import React from 'react'
import { Narrative as FhirNarrative } from '../../../../types/FHIRTypes/Narrative'

interface NarrativeProps {
    text: FhirNarrative
}

const Narrative: React.FC<NarrativeProps> = ({ text }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: text.div }} />
    )
}

export default Narrative