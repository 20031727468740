import React, { FC, useEffect } from 'react'
import { CarePlanBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { capitalizeFirstLetter, handleCodeableConcept, handleReference } from '../../../../../utils/helpers'
import CarePlanActivity from '../CarePlanActivity'
import CareTeamDetail from '../../CareTeams/CareTeamDetail'
import { buildPeriod } from '../../../../../utils/helpers'
import ProvenanceDetail from '../../Provenance'
import useFhirResourceById from '../../../../../hooks/admin/useFhirResourceById'

interface Props {
  carePlanDetailId: string;
  carePlanData: CarePlanBundle;
  isFetching: boolean;
}

const CarePlanDetail: FC<Props> = ({ carePlanDetailId, carePlanData, isFetching }) => {

  const carePlanEntry = carePlanData?.entry?.find((carePlan: any) => carePlan.resource.id === carePlanDetailId)
  const carePlanResource = carePlanEntry?.resource

  const includedCareTeams = carePlanResource?.careTeam?.map((ref: any) => {
    return carePlanData?.entry?.find((entry: any) => entry.resource.id === ref.reference.split('/')[1])
  }).filter((entry: any) => entry !== undefined)

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          carePlanResource ? (
            <Row>
              <Col sm={3}>
                <dt>Care Plan ID</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.id}</dd>
              </Col>
              <Col sm={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Summary</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <dd dangerouslySetInnerHTML={{ __html: carePlanResource.text?.div || '' }} />
                    <dd>Status: {capitalizeFirstLetter(carePlanResource.text?.status || '')}</dd>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={3}>
                <dt>Based On</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.basedOn?.map((basedOn) => handleReference(basedOn)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Replaces</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.replaces?.map((replaces) => handleReference(replaces)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Part Of</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.partOf?.map((partOf) => handleReference(partOf)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Status</dt>
              </Col>
              <Col sm={9}>
                <dd>{capitalizeFirstLetter(carePlanResource.status)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Intent</dt>
              </Col>
              <Col sm={9}>
                <dd>{capitalizeFirstLetter(carePlanResource.intent)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Category</dt>
              </Col>
              <Col sm={9}>
                <dd>{Array.isArray(carePlanResource.category) ? carePlanResource.category.map((category) => handleCodeableConcept(category)).join(', ') : ''}</dd>
              </Col>
              <Col sm={3}>
                <dt>Title</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.title}</dd>
              </Col>
              <Col sm={3}>
                <dt>Description</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.description}</dd>
              </Col>
              <Col sm={3}>
                <dt>Subject</dt>
              </Col>
              <Col sm={9}>
                <dd>{handleReference(carePlanResource.subject)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Encounter</dt>
              </Col>
              <Col sm={9}>
                <dd>{handleReference(carePlanResource.encounter)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Period</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.period ? buildPeriod(carePlanResource.period) : ''}</dd>
              </Col>
              <Col sm={3}>
                <dt>Created Date</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.created}</dd>
              </Col>
              <Col sm={3}>
                <dt>Author</dt>
              </Col>
              <Col sm={9}>
                <dd>{handleReference(carePlanResource.author)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Contributor</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.contributor?.map((contributor) => handleReference(contributor)).join(', ')}</dd>
              </Col>
              <Col sm={12} className='my-2'>
                {includedCareTeams && includedCareTeams.length > 0
                  ? includedCareTeams.map((careTeam: any) => (
                    <CareTeamDetail
                      key={careTeam.resource.id}
                      careTeamData={{ 
                        id: 'careTeamBundleId', 
                        resourceType: 'Bundle', 
                        total: 1, 
                        type: 'searchset', 
                        link: [], 
                        entry: [careTeam],
                        meta: {}
                      }}
                      careTeamDetailId={careTeam.resource.id}
                      isFetching={false}
                    />
                  ))
                  : null}
              </Col>
              <Col sm={3}>
                <dt>Addresses</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.addresses?.map((addresses) => handleReference(addresses)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Supporting Info</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.supportingInfo?.map((supportingInfo) => handleReference(supportingInfo)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Goal</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.goal?.map((goal) => handleReference(goal)).join(', ')}</dd>
              </Col>
              <Col sm={12}>
                <CarePlanActivity patientCarePlan={carePlanResource} />
              </Col>
              <Col sm={3}>
                <dt>Note</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.note?.map((note) => note.text).join(', ')}</dd>
              </Col>

              <Col sm={12}>
                {isFetching ? (
                  <div className='d-flex dataContainer'>
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                    <span style={{ marginLeft: '10px' }}>Loading Provenance Data...</span>
                  </div>
                ) : (
                  <ProvenanceDetail resourceName='CarePlan' resourceId={carePlanDetailId} />
                )}
              </Col>
            </Row>


          ) : (
            <div className='text-center'>
              No Care Plan found for this member.
            </div>
          )
        }
      </dl>
    </>
  )
}

export default CarePlanDetail