import React, { useEffect } from 'react'
import useFhirResource from '../../../../../hooks/admin/useFhirResource'
import { Col, Row } from 'react-bootstrap'
import { handleCodeableConcept } from '../../../../../utils/helpers'

interface PractitionerRoleProps {
    role: any;
}


const PractitionerRole: React.FC<PractitionerRoleProps> = ({ role }) => {
    const networkOrganizationId = role.extension[0].valueReference.reference.split('/')[1]

    const { data: network, refetch: fetchNetwork, isLoading } = useFhirResource(networkOrganizationId, 'Organization')

    useEffect(() => {
        if (networkOrganizationId) {
            fetchNetwork()
        }
    }, [networkOrganizationId])

    if (isLoading) return <div>Loading...</div>

    return (
        <dd>
            <Row>
                <Col>{network?.name || ''}</Col>
                <Col>{role.specialty.map((specialty: any) => handleCodeableConcept(specialty)).join(', ')}</Col>
            </Row>
        </dd>
    )
}

export default PractitionerRole