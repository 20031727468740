import React, { ChangeEvent, FC, FocusEvent } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { BsEye, BsEyeSlash } from 'react-icons/bs'

interface Props {
    showPassword: boolean
    placeholder: string
    name: string
    value: string
    handleChange: (e: ChangeEvent<any>) => void
    handleBlur: (e: FocusEvent<any>) => void
    isInvalid: boolean
    toggleShowPassword: () => void
    autoComplete: string
}

const PasswordInput: FC<Props> = ({ showPassword, placeholder, name, value, handleChange, handleBlur, isInvalid, toggleShowPassword, autoComplete }) => {
    return (
        <InputGroup>
            <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder={placeholder}
                name={name}
                autoComplete={autoComplete}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={isInvalid}
            />
            <InputGroup.Append>
                <Button className='custom' onClick={toggleShowPassword}>
                    {showPassword ? <BsEye /> : <BsEyeSlash />}
                </Button>
            </InputGroup.Append>
        </InputGroup>
    )
}

export default PasswordInput