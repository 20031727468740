//! Edited - bottom card text

import React, { FC } from 'react'
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from 'react-bootstrap'
import ThirdPartyApp from '../../../types/ThirdPartyApp'
import '../../../App.scss'



import { FormattedMessage, IntlProvider } from 'react-intl'

import {
  companyMessage,
  companyNameFull,
  preferedName,
  dataPortalTitle,
  contactLink,
  contactEmail,
  contactPhone,
} from '../../../assets/i18n/i18n'
//---------------

interface Props {
  apps: Array<ThirdPartyApp>;
}

const MemberApps: FC<Props> = ({ apps }: Props) => {
  return (
    <IntlProvider locale='en' messages={companyMessage}>

      <Container
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* <Breadcrumb>
          <Breadcrumb.Item href="/">Member Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Applications</Breadcrumb.Item>
        </Breadcrumb> */}
        <Row>
          <Col md={8} sm={12}>
            <Card className="blueCardOutline h-100">
              <Card.Body className="disclaimerText text-muted">
                <Container>
                  <ListGroup className="listGroupHover">
                    {apps &&
                      apps.map((app) => (
                        <ListGroup.Item
                          key={app.appName}
                          className="list-group-hover"
                          action
                          href={`/app/${app.appId}`}
                        >
                          <div
                            className="d-flex justify-content-between"
                            style={{ fontSize: 'calc(.45em + 1vw)' }}
                          >
                            <div>{app.appName}</div>
                            <div>Get Info</div>
                          </div>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </Container>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={12}>
            <Card className="blueCardOutline h-100 mt-md-0 mt-4">
              <Card.Body>
                <Card.Title className="cardHeaderText">
                  Is my Health information safe?
                </Card.Title>
                <Card.Text>
                  {companyNameFull} will never share your health information with any
                  outside party without your consent. {companyNameFull} is not responsible
                  for your health information once you authorize an external
                  application access to it. {companyNameFull} recommends reviewing the
                  terms and use of any external app prior to granting them access
                  to your health information. Only grant access to apps you trust.
                  {companyNameFull} also recommends limiting the number of external apps
                  you grant access to in order to limit potential exposure.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </IntlProvider>

  )
}

export default MemberApps
