import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useStatuses(): any {
  return useQuery('admin-status', async () => {
    return await request.get(`${getApiServer()}/admin`).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 5 * 60 * 1000,
    retry: 0
  })
}