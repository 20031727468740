import React, { FC, useEffect, useState } from 'react'
import { Alert, Breadcrumb, Button, Col, Container, Form, Row } from 'react-bootstrap'
import ThirdPartyApp from '../../../types/ThirdPartyApp'
import { capitalizeFirstLetter, buildPhone } from '../../../utils/helpers'

interface Props {
  app: ThirdPartyApp;
  updateApp: any;
}

const Application: FC<Props> = ({ app, updateApp }: Props) => {
  const [showAlertState, setShowAlertState] = useState({ show: false, variant: 'info', message: '' })

  const [appStatus, setAppStatus] = useState('')

  useEffect(() => {
    if (updateApp.isSuccess) {
      const appStatus = updateApp.data.status === 'APPROVED' ? 'approved' : 'de activated'
      setShowAlertState({
        show: true,
        variant: 'success',
        message: `Application was successfully ${appStatus}.`
      })
    }

    if (updateApp.isError) {
      setShowAlertState({
        show: true,
        variant: 'danger',
        message: 'Error updating application: '
      })
    }
    setAppStatus('')
  }, [updateApp.isSuccess, updateApp.isError])


  const handleUpdateApp = () => {
    updateApp.mutate({
      ...app,
      status: appStatus
    })
  }

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      {/* <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/apps?status=All'>Apps</Breadcrumb.Item>
        <Breadcrumb.Item active>{app?.appName}</Breadcrumb.Item>
      </Breadcrumb> */}
      <h2>App Detail</h2>
      {
        showAlertState.show && (
          <Alert variant={showAlertState.variant} dismissible onClose={() => setShowAlertState((ps) => ({ ...ps, show: false }))}>
            {showAlertState.message}
            {
              updateApp.isError && (<div dangerouslySetInnerHTML={{ __html: updateApp.error.response.text }} />)
            }
          </Alert>
        )
      }
      <dl className='dataContainer'>
        <Row>
          <Col sm={3}>
            <dt>Status</dt>
          </Col>
          <Col sm={9}>
            <dd>{app && capitalizeFirstLetter(app.status)}</dd>
          </Col>
          <Col sm={3}>
            <dt>Change Status</dt>
          </Col>
          <Col sm={9}>
            <dd>
              <Row>
                <Col>
                  <Form.Control
                    name='appStatusSelect'
                    as='select'
                    size='sm'
                    value={appStatus}
                    onChange={(event: any) => setAppStatus(event.target.value)}
                    placeholder='Choose App Status'
                  >
                    <option disabled value=''>
                      Choose App Status
                    </option>
                    <option value='APPROVED'>Approved</option>
                    <option value='NOT APPROVED'>Not Approved</option>
                    <option value='INACTIVE'>Inactive</option>
                  </Form.Control>
                </Col>
                <Col>
                  <Button onClick={handleUpdateApp} disabled={appStatus === ''}>Update</Button>
                </Col>
              </Row>
            </dd>
          </Col>
          <Col sm={3}>
            <dt>App Name</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {app?.appName}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>App Description</dt>
          </Col>
          <Col sm={12}>
            <dd>
              {app?.appDescription}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Company Name</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {app?.companyName}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Address</dt>
          </Col>
          <Col sm={9}>
            <dd>{`${app?.address1}${app?.address2 ? ' '.concat(app?.address2) : ''}, ${app?.city} ${app?.state}, ${app?.zip}`}</dd>
          </Col>
          <Col sm={3}>
            <dt>Web Url</dt>
          </Col>
          <Col sm={9}>
            <dd>
              <a href={app?.url} target='_blank' rel="noreferrer">{app?.url}</a>
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Carin Attested?</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {app?.readCarin ? 'Yes' : 'No'}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Terms and Conditions</dt>
          </Col>
          <Col sm={9}>
            <dd>
              <a href={app?.termsUrl} target='_blank' rel="noreferrer">{app?.appName} Terms and Conditions</a>
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Redirect Url</dt>
          </Col>
          <Col sm={9}>
            <dd>
              {app?.redirectUrl}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>iOS App ID</dt>
          </Col>
          <Col sm={3}>
            <dd>
              {app?.iosAppId}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>iOS App Url</dt>
          </Col>
          <Col sm={3}>
            <dd>
              {app?.iosAppUrl}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Android App ID</dt>
          </Col>
          <Col sm={3}>
            <dd>
              {app?.androidAppId}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Android App Url</dt>
          </Col>
          <Col sm={3}>
            <dd>
              {app?.androidAppUrl}
            </dd>
          </Col>
          <Col sm={3}>
            <dt>Contact Name</dt>
          </Col>
          <Col sm={9}>
            <dd>{app?.contactName}</dd>
          </Col>
          <Col sm={3}>
            <dt>Email</dt>
          </Col>
          <Col sm={3}>
            <dd>{app?.email}</dd>
          </Col>
          <Col sm={3}>
            <dt>Phone</dt>
          </Col>
          <Col sm={3}>
            <dd>{buildPhone(app?.phone)}</dd>
          </Col>
          <Col sm={12}>
            <h5>Registered Directories</h5>
          </Col>
          <Col sm={3}>
            <dt>CAQH Directory</dt>
          </Col>
          <Col sm={3}>
            <dd>{app?.caqhDirectory ? 'Yes' : 'No'}</dd>
          </Col>
          <Col sm={3}>
            <dt>Change Health Directory</dt>
          </Col>
          <Col sm={3}>
            <dd>{app?.changeHealthcareDirectory ? 'Yes' : 'No'}</dd>
          </Col>
          <Col sm={3}>
            <dt>Med Effects Directory</dt>
          </Col>
          <Col sm={3}>
            <dd>{app?.medEffectsDirectory ? 'Yes' : 'No'}</dd>
          </Col>
          {
            app?.otherDirectory && (
              <>
                <Col sm={3}>
                  <dt>Other</dt>
                </Col>
                <Col sm={3}>
                  <dd>{app.otherDirectory}</dd>
                </Col>
              </>
            )
          }
        </Row>
      </dl>
    </Container>
  )
}

export default Application