import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { capitalizeFirstLetter, formatDateWithFormat } from '../../../../utils/helpers'
import { Meta } from '../../../../types/FHIRTypes/Meta'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface Props {
    resourceType: string | undefined;
    resourceId: string | undefined;
    resourceMeta: Meta | undefined;
    resourceLanguage: string | undefined;
    resourceImplicitRules: string | undefined;
}

const MetaDataFooter: FC<Props> = ({ resourceType, resourceId, resourceMeta, resourceLanguage, resourceImplicitRules }: Props) => {
    return (
        <div className='footer'>
            <hr />
            <h6>FHIR Resource Metadata</h6>
            <Row>
                <Col sm={3}>
                    <dt>Resource Type</dt>
                </Col>
                <Col sm={9}>
                    <dd>{getContentOrFallback(capitalizeFirstLetter(resourceType))}</dd>
                </Col>
                <Col sm={3}>
                    <dt>Resource ID</dt>
                </Col>
                <Col sm={9}>
                    <dd>{getContentOrFallback(resourceId)}</dd>
                </Col>
                <Col sm={3}>
                    <dt>Resource Last Updated</dt>
                </Col>
                <Col sm={9}>
                    <dd>{getContentOrFallback(resourceMeta?.lastUpdated ? formatDateWithFormat(resourceMeta.lastUpdated, 'MM/dd/yyyy HH:mm:ss') : '')}</dd>
                </Col>
                {
                    resourceMeta?.profile && (
                        <>
                            <Col sm={3}>
                                <dt>Resource Profile</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>
                                    {
                                        resourceMeta.profile.map((profile, index) => (
                                            <div key={`resource-profile-${index}`}>{getContentOrFallback(profile)}</div>
                                        ))
                                    }
                                </dd>
                            </Col>
                        </>
                    )
                }
                {
                    resourceLanguage && (
                        <>
                            <Col sm={3}>
                                <dt>Resource Language</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{getContentOrFallback(resourceLanguage)}</dd>
                            </Col>
                        </>
                    )
                }
                {
                    resourceImplicitRules && (
                        <>
                            <Col sm={3}>
                                <dt>Resource Implicit Rules</dt>
                            </Col>
                            <Col sm={9}>
                                <dd>{getContentOrFallback(resourceImplicitRules)}</dd>
                            </Col>
                        </>
                    )
                }
            </Row>
        </div>
    )
}

export default MetaDataFooter
