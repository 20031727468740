import React, { FC } from 'react'
import { HumanName as Name } from '../../../../types/FHIRTypes/HumanName'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface HumanNameProps {
    name: Name;
    showUse?: boolean;
}

const HumanName: FC<HumanNameProps> = ({ name, showUse }) => {
    const getName = () => {
        const fullName = name.text || `${name.given?.join(' ') || ''} ${name.family || ''}`.trim()
        const useType = showUse && name.use ? `Type: ${name.use}` : ''
        return `${fullName} ${useType}`
    }

    return (
        <span>
            {getContentOrFallback(getName())}
        </span>
    )
}

export default HumanName