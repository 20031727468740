import request from 'superagent'
import { useMutation } from 'react-query'
import { getApiServer } from '../../utils/domain'
import { CustomHttpError } from '../../types/CustomHttpError'

export default function useValidateMember() {
  return useMutation(
    (values: any) => {
      return request
        .post(`${getApiServer()}/auth/validate-member`)
        .set('Accept', 'application/json')
        .send(values)
        .then((res) => res.body)
    },
    {
      onError: (error: CustomHttpError) => {
        return error.response.text
      }
    }
  )
}
