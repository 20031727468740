import React, { FC } from 'react'
import { formatImmunizationDataForTable } from '../../../../utils/fhirUtils/immunization'
import { buildPatientId, buildPatientName } from '../../../../utils/fhirUtils/patient'
import ImmunizationList from './ImmunizationList'
import ImmunizationDetail from './ImmunizationDetail'

interface Props {
  immunizationData: any;
  patientData: any;
  immunizationDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const Immunizations: FC<Props> = ({ immunizationData, patientData, immunizationDetailId, page, isFetching, handleChangePage }: Props) => {
  // const patientImmunizations = formatImmunizationDataForTable(immunizationData, buildPatientId(patientData))
  return (
    <div>
      <h3>Immunizations</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
      {
        immunizationDetailId && page === 'Immunization' ? (
          <ImmunizationDetail detailId={immunizationDetailId} immunizationData={immunizationData} patientData={patientData} isFetching={isFetching} />
        ) : <ImmunizationList patientImmunizations={immunizationData} patientData={patientData} isFetching={isFetching} handleChangePage={handleChangePage} />
      }
    </div>
  )
}
export default Immunizations