import React, { FC, useEffect, useState } from 'react'
import { ExplanationOfBenefitBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildEOBBillableStart, buildEOBInsurer, buildEOBOutcome, buildEOBDiagnosis, buildEOBPayment, buildEOBBillableEnd, buildEOBCreatedDate, buildEOBPayor, buildEOBPatientPayment } from '../../../../../utils/fhirUtils/eob'
import { handleCodeableConcept, formatDateWithFormat } from '../../../../../utils/helpers'
import useFhirResourceById from '../../../../../hooks/admin/useFhirResourceById'
import useFhirResource from '../../../../../hooks/admin/useFhirResource'
import EobItem from '../EobItem'
import { appConfig } from '../../../../../assets/customizations/config'
import { useHistory } from 'react-router-dom'
import { buildPatientName } from '../../../../../utils/fhirUtils/patient'
import ProvenanceDetail from '../../Provenance'
import { Provenance } from '../../../../../types/FHIRTypes/Provenance'


interface Props {
  eobDetailId: string;
  eobData: ExplanationOfBenefitBundle;
  isFetching: boolean;
  patientData: any
}

const EobDetail: FC<Props> = ({ eobDetailId, eobData, isFetching, patientData }) => {
  const [eob, setEob] = useState<any>(null)
  const history = useHistory()

  useEffect(() => {
    if (!eobData) return

    const eobEntry = eobData?.entry?.find((eob) => eob.resource.id === eobDetailId)
    if (eobEntry) {
      setEob(eobEntry.resource)
    } else {
      history.push('/404')
    }
  }, [eobData])

  // org stuff
  const organization = eob?.contained?.[0]?.payor?.[0]?.reference ?? 'no insurer'
  const trimmedOrg = organization?.split('/')[1]
  const { data: orgData, refetch: getOrg, isFetching: fetchingOrgs } = useFhirResource(trimmedOrg, 'Organization')
  useEffect(() => {
    if (trimmedOrg) {
      getOrg()
    }
  }, [trimmedOrg])


  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span style={{ marginLeft: '10px' }}>Loading EOB Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          eob ? (
            <Row>
              <Col sm={3} className='mt-3'>
                <dt>Claim ID</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.identifier?.[0].value}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Last Updated</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.meta?.lastUpdated ? formatDateWithFormat(eob.meta.lastUpdated, 'MM/dd/yyyy HH:mm:ss') : ''}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Identifier</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.identifier?.[0].value}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Status</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.status.toUpperCase()}</dd>
              </Col>

              <Col sm={3} className='mt-3'>
                <dt>Type</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{handleCodeableConcept(eob.type)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Use</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.use}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Patient</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{patientData ? buildPatientName(patientData.name) : ''}</dd>
              </Col>
              {
                eob.billablePeriod && (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Service Period Start</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      <dd>{buildEOBBillableStart(eob.billablePeriod)}</dd>
                    </Col>
                    <Col sm={3} className='mt-3'>
                      <dt>Service Period End</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      <dd>{buildEOBBillableEnd(eob.billablePeriod)}</dd>
                    </Col>
                  </>
                )
              }
              <Col sm={3} className='mt-3'>
                <dt>Created Date</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{buildEOBCreatedDate(eob.created)}</dd>
              </Col>
              <Col sm={3} className="mt-3">
                <dt>Insurer</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                {eob.insurer?.display ? (
                  <dd>{eob.insurer.display}</dd>
                ) : fetchingOrgs ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    size="sm"
                  />
                ) : (
                  <dd>{buildEOBInsurer(orgData)}</dd>
                )}
              </Col>
              <Col sm={3} className="mt-3">
                <dt>Provider</dt>
              </Col>
              <Col sm={9} className="mt-3">
                <dd>{eob.provider.reference}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Related</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>
                  {eob.related && eob.related.length > 0 ? (
                    eob.related.map((relatedItem: any, index: number) => (
                      <div key={index}>
                        <strong>Claim:</strong> {relatedItem.claim?.reference}<br />
                        <strong>Relationship:</strong> {handleCodeableConcept(relatedItem.relationship)}
                      </div>
                    ))
                  ) : (
                    ''
                  )}
                </dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Payee type</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{handleCodeableConcept(eob.payee?.type)}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Payee Party</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.payee?.party?.reference}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Outcome</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                <dd>{eob.outcome}</dd>
              </Col>
              <Col sm={3} className='mt-3'>
                <dt>Care Team</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                {eob.careTeam?.map((teamMember: any, index: number) => (
                  <div key={`eob-careTeam-${index}`} className='mb-3'>
                    <strong>Sequence:</strong> {teamMember.sequence || ''}<br />
                    <strong>Provider:</strong> {teamMember.provider?.reference || ''}<br />
                    <strong>Role:</strong> {teamMember.role ? handleCodeableConcept(teamMember.role) : ''}
                  </div>
                ))}
              </Col>

              <Col sm={3} className='mt-3'>
                <dt>Supporting Information</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                {eob.supportingInfo?.map((info: any, index: number) => (
                  <div key={`eob-supporting-info-${index}`}>
                    <dd>
                      {info.sequence && <strong>Sequence:</strong>} {info.sequence}<br />
                      {info.category && <strong>Category:</strong>} {handleCodeableConcept(info.category)}<br />
                    </dd>
                  </div>
                ))}
              </Col>
              {
                eob.diagnosis && (
                  <>
                    <Col sm={3} className='mt-3'>
                      <dt>Diagnosis</dt>
                    </Col>
                    <Col sm={9} className='mt-3'>
                      {buildEOBDiagnosis(eob.diagnosis)}
                    </Col></>
                )
              }
              <Col sm={3} className='mt-3'>
                <dt>Insurance</dt>
              </Col>
              <Col sm={9} className='mt-3'>
                {eob.insurance?.map((insuranceItem: any, index: number) =>
                  insuranceItem.focal && (
                    <div key={`eob-insurance-${index}`} className='mb-3'>
                      {insuranceItem.coverage?.reference}
                    </div>
                  )
                )}
              </Col>

              <Col sm={3} className='mt-3'>
                <dt>Items</dt>
              </Col>
              <Col sm={9}>
                <EobItem patientEob={eob} />
              </Col>

              <Col sm={3} className='mt-3'>
                <dt>Payment</dt>
              </Col>

              {
                appConfig.use_eob_member_responsibility && (
                  <Col sm={9} className='mt-3'>
                    <dd>{buildEOBPatientPayment(eob)}</dd>
                  </Col>
                )
              }
              <Col sm={3} className='mt-3'>
                <dt>Process Note</dt>
              </Col>

              <Col sm={9} className='mt-3'>
                <div>
                  {eob.processNote?.map((note: any, index: number) => (
                    <div key={`process-note-${index}`}>
                      <dd>{note.text}</dd>
                    </div>
                  ))}
                </div>
              </Col>
              <Col sm={12}>
              <ProvenanceDetail resourceName='ExplanationOfBenefit' resourceId={eobDetailId} />
              </Col>
            </Row>
          ) : null
        }
      </dl>
    </>
  )
}

export default EobDetail