import { api_port } from '../../package.json'

export const getApiServer = (): string => {
  const { protocol, hostname } = window.location
  
  let apiUrl = `${protocol}//${hostname}/api`

  if(process.env.NODE_ENV!=='production' && api_port!=='') {
    apiUrl = `${protocol}//${hostname}:${api_port}/api`
  }
  return apiUrl
}