import React, { FC } from 'react'
import { Image } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import powerByHLX from '../../assets/customizations/logos/HealthLX.svg'
import connectID from '../../assets/customizations/logos/ConnectID.svg'

import '../../App.scss'
import { IntlProvider } from 'react-intl'

import {
  companyMessage,
} from '../../assets/i18n/i18n'



const Footer: FC = () => {
  const location = useLocation()
  const linkText = !location.pathname.includes('/developers') && location.pathname !== '/app-register' && !location.pathname.includes('/app-edit') ? 'For Application Developers' : 'Home'
  const linkRoute = !location.pathname.includes('/developers') && location.pathname !== '/app-register' && !location.pathname.includes('/app-edit') ? '/developers' : '/login'
  return (
    <IntlProvider locale='en' messages={companyMessage}>
      <footer>
        <h3><Link to={linkRoute}>{linkText}</Link></h3>
        <nav id='secondary_nav' aria-label='secondary navigation'>
          <ul>
            <li><Link to="/terms">Terms and Conditions</Link></li>
            <li><Link to="/developers">API Information</Link></li>
          </ul>
        </nav>

        <p>
          <a href="https://teschglobal.com/connectid/" target="_blank" rel="noreferrer">
            <Image style={{ maxHeight: '32px', margin: '0%' }} className='img-fluid' src={connectID} alt="ConnectID." />
          </a>
        </p>
        <p>
          <a href="https://www.healthlx.com" target="_blank" rel="noreferrer">
            <Image style={{ maxHeight: '32px', margin: '0%' }} className='img-fluid' src={powerByHLX} alt="HealthLX. A TESCHGlobal Company." />
          </a>
        </p>
      </footer>
    </IntlProvider>
  )
}

export default Footer