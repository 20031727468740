//! Edited - No Change
//PAGE CURRENTLY NOT IN USE

import React, { FC } from 'react'
import { Breadcrumb, Card, Col, Container, Image, Row } from 'react-bootstrap'
import IOSBadge from '../../../assets/imagesOLD/badges/app_store_icon.png'
import GooglePlayBadge from '../../../assets/imagesOLD/badges/google_play_icon.png'
import ThirdPartyApp from '../../../types/ThirdPartyApp'

// import './index.css'
import '../../../App.scss'

interface Props {
  app: ThirdPartyApp;
}

const App: FC<Props> = ({ app }: Props) => {
  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {/* <Breadcrumb>
        <Breadcrumb.Item href="/">Member Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/apps?status=APPROVED">
          Applications
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{app && app.appName}</Breadcrumb.Item>
      </Breadcrumb> */}
      <Card>
        <Card.Body>
          <Row>
            <Col md={8} className="h-100">
              <Card.Title className="appCardHeaderText">
                {app && app.appName}
              </Card.Title>
              <Card.Text className="appUrlText">
                {' '}
                App Website URL:{' '}
                <a
                  href={app?.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#047fbb' }}
                >
                  {app?.url}
                </a>
              </Card.Text>
              <div className="d-flex">
                <div style={{ width: '175px' }}>
                  <a
                    href={app && app.iosAppUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={IOSBadge} fluid style={{ width: '93%' }} />
                  </a>
                </div>
                <div style={{ width: '175px' }}>
                  <a
                    href={app && app.androidAppUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image src={GooglePlayBadge} fluid />
                  </a>
                </div>
              </div>
              {app && app.appDescription && (
                <Card.Text className="appText text-muted mt-3">
                  App Description: {app && app.appDescription}
                </Card.Text>
              )}
            </Col>
            <Col md={4} className="h-100 appText text-muted mt-md-0 mt-3">
              {app && app.readCarin == 1 && (
                <Card.Text>
                  This application has attested to the Carin Code of Conduct.
                </Card.Text>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default App
