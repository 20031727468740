import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useUserAuthenticated(): any {
    return useQuery('user-authenticated', async () => {
        const isAuthenticated = await request.get(`${getApiServer()}/auth/authenticated`).set('Accept', 'application/json').withCredentials()
        return isAuthenticated.body
    }, {
        retry: 0,
        enabled: false
    })
}