import React from 'react'
import { Button } from 'react-bootstrap'
import { scrollToTop } from '../../utils/helpers'

interface PaginationButtonsProps {
    backDisabled: boolean
    nextDisabled: boolean
    onBackClick: () => void
    onNextClick: () => void
}

const PaginationButtons: React.FC<PaginationButtonsProps> = ({ backDisabled, nextDisabled, onBackClick, onNextClick }) => {
    const onButtonClick = (type: 'back' | 'next') => {
        if (type === 'back') {
            onBackClick()
        } else {
            onNextClick()
        }
        scrollToTop()
    }

    return (
        <>
            <Button
                variant='light'
                disabled={backDisabled}
                onClick={() => onButtonClick('back')}
            >
                {'<'} Back
            </Button>
            <Button
                className='app-button pagination-button'
                disabled={nextDisabled}
                onClick={() => onButtonClick('next')}
            >
                Next {'>'}
            </Button>
        </>
    )
}

export default PaginationButtons