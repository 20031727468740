const dataAbsentReasonMapping: { [key: string]: string } = {
  'unknown': 'Unknown',
  'asked-unknown': 'Asked But Unknown',
  'temp-unknown': 'Temporarily Unknown',
  'not-asked': 'Not Asked',
  'asked-declined': 'Asked But Declined',
  'masked': 'Masked',
  'not-applicable': 'Not Applicable',
  'unsupported': 'Unsupported',
  'as-text': 'As Text',
  'error': 'Error',
  'not-a-number': 'Not a Number (NaN)',
  'negative-infinity': 'Negative Infinity (NINF)',
  'positive-infinity': 'Positive Infinity (PINF)',
  'not-performed': 'Not Performed',
  'not-permitted': 'Not Permitted'
}

export const getDataAbsentReason = (resource: any, property: string): string | undefined => {
  const extensionProperty = `_${property}`
  const extension = resource[extensionProperty]?.extension?.find(
    (ext: any) => ext.url === 'http://hl7.org/fhir/StructureDefinition/data-absent-reason'
  )
  if (extension && extension.valueCode) {
    const displayValue = dataAbsentReasonMapping[extension.valueCode] || extension.valueCode
    return `Data absent reason: ${displayValue}`
  }
  return undefined
}