//! Edited - No Change
import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { useFormik } from 'formik'
import { CreateAccountSchema } from '../../../utils/validation'
import PasswordInput from '../../PasswordInput'
import InfoPopover from '../../InfoPopover'

import '../../../App.scss'

interface InitialState {
  username: string;
  password: string;
  passwordAgain: string;
}

interface Props {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  isError: boolean;
  error: string;
  initialValues: InitialState;
}

const CreateAccount: FC<Props> = ({
  initialValues,
  onSubmit,
  isLoading,
  isError,
  error,
}: Props) => {
  const [alertState, setAlertState] = useState({
    variant: 'success',
    show: true,
    message:
      'The information you entered has been verified in our system and we have verified your email for notifications. Now you can create an account',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showSecondPassword, setShowSecondPassword] = useState(false)


  const formik = useFormik({
    initialValues: {
      ...initialValues,
      recaptcha: null
    },
    validationSchema: CreateAccountSchema,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    if (isError) {
      setAlertState({
        variant: 'danger',
        show: true,
        message: error,
      })
    }
  }, [isError])

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className='formContainer'>
      {alertState.show && (
        <Alert
          variant={alertState.variant}
          dismissible
          onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}
        >
          {alertState.message}
        </Alert>
      )}
      <Form.Group>
        <Form.Label>
          Username:
          <InfoPopover title='Username Requirements' content='Usernames are not case sensitive.' />
        </Form.Label>
        <Form.Control
          type="text"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.username && formik.touched.username}
          placeholder="Create New Username"
          autoComplete="username"
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.username}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Password:
          <InfoPopover title='Password Requirements' content='Password must be a minimum of 16 characters. Please include at least 1 Uppercase letter, 1 Lowercase letter, 1 Number, or 1 Special Character.' />
        </Form.Label>
        <PasswordInput
          showPassword={showPassword}
          placeholder='Create Password'
          name='password'
          value={formik.values.password}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          isInvalid={Boolean(!!formik.errors.password && formik.touched.password)}
          toggleShowPassword={() => setShowPassword(!showPassword)}
          autoComplete='new-password'
        />
        {
          Boolean(!!formik.errors.password && formik.touched.password) && (
            <Form.Text className='text-danger'>
              {formik.errors.password}
            </Form.Text>
          )
        }
      </Form.Group>
      <Form.Group>
        <Form.Label>Enter Password Again:</Form.Label>
        <PasswordInput
          showPassword={showSecondPassword}
          placeholder='Repeat Password'
          name='passwordAgain'
          value={formik.values.passwordAgain}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          isInvalid={Boolean(!!formik.errors.passwordAgain && formik.touched.passwordAgain)}
          toggleShowPassword={() => setShowSecondPassword(!showSecondPassword)}
          autoComplete='new-password'
        />
        {
          Boolean(!!formik.errors.passwordAgain && formik.touched.passwordAgain) && (
            <Form.Text className='text-danger'>
              {formik.errors.passwordAgain}
            </Form.Text>
          )
        }
      </Form.Group>
      <Button className="custom" type="submit" disabled={isLoading}>
        {isLoading ? (
          <Spinner
            as="span"
            animation="border"
            role="status"
            size="sm"
            aria-hidden="true"
          />
        ) : (
          'Create Account'
        )}
      </Button>
    </Form>
  )
}

export default CreateAccount
