import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { GlobalState } from '../../types/GlobalState'
import { ProviderResource } from '../../types/ProviderResource'

export const useGlobalState = create<GlobalState>()(
    persist(
        (set) => ({
            fhirId: '',
            setFhirId: (fhirId: string) => set(() => ({ fhirId })),
            providerResource: 'Organization',
            setProviderResource: (providerResource: string) => set(() => ({ providerResource })),
            providerSearchTerms: '',
            setProviderSearchTerms: (providerSearchTerms: string) => set(() => ({ providerSearchTerms })),
            provider: null,
            setProvider: (provider: ProviderResource | null) => set(() => ({ provider }))
        }),
        {
            name: 'global-state-storage',
            storage: createJSONStorage(() => localStorage)
        }
    )
)