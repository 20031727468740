import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { CarePlan, Activity } from '../../../../../types/FHIRTypes/CarePlan'
import { handleCodeableConcept, handleReference } from '../../../../../utils/helpers'
import CarePlanActivityDetail from '../CarePlanActivityDetail'

interface Props {
  patientCarePlan: CarePlan;
}

const CarePlanActivity: FC<Props> = ({ patientCarePlan }) => {
  const activities: Activity[] = patientCarePlan.activity || []

  return (
    <>
      {activities.map((activity, index) => (
        <Card key={`care-plan-activity-${index}`} className='mt-3 w-100'>
          <Card.Header>
            <div>
              <p>Activity {index + 1}</p>
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <dt>Outcome</dt>
              </Col>
              <Col sm={9}>
                <dd>{activity.outcomeCodeableConcept?.map((outcome) => handleCodeableConcept(outcome)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Outcome Reference</dt>
              </Col>
              <Col sm={9}>
                <dd>{activity.outcomeReference?.map((outcome) => handleReference(outcome)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Progress</dt>
              </Col>
              <Col sm={9}>
                <dd>{activity.progress?.map((progress) => progress.text).join(', ')}</dd>
              </Col>
              {activity.reference ? (
                <>
                  <Col sm={3}>
                    <dt>Reference</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(activity.reference)}</dd>
                  </Col>
                </>
              ) : activity.detail ? (
                <>
                  <Col sm={12}>
                    <CarePlanActivityDetail activity={activity} />
                  </Col>
                </>
              ) : null}
            </Row>
          </Card.Body>
        </Card>
      ))}
    </>
  )
}

export default CarePlanActivity