import { isCodeableConcept } from '../../typeGuards/fhirTypes'
import { CodeableConcept } from '../../types/FHIRTypes/CodeableConcept'
import { Extension } from '../../types/FHIRTypes/Extension'
import { handleCodeableConcept, handleExtension } from '../helpers'

export const handleCodeableConceptOrExtension = (value: CodeableConcept | CodeableConcept[] | Extension | undefined): string => {
    if (!value) return ''

    if (Array.isArray(value) && value.every(isCodeableConcept)) {
        return value.map((v) => handleCodeableConcept(v)).join(', ')
    }

    if (isCodeableConcept(value)) {
        return handleCodeableConcept(value)
    }

    return handleExtension(value)
}