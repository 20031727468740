import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationButtons from '../../../../PaginationButtons'

interface NetworkListProps {
    networks: any
    page: number
    isFetching: boolean
    handleNextPage: () => void
    handlePrevPage: () => void
    isLoading: boolean
    isSuccess: boolean
}

const NetworkList: React.FC<NetworkListProps> = ({ networks, page, isFetching, handleNextPage, handlePrevPage, isLoading, isSuccess }) => {
    return (

        <section>
            <h3>Networks</h3>
            <Table className='mt-3' size='sm' hover>
                <thead>
                    <tr>
                        <th className='name-column'>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading || isFetching ? (
                            <tr>
                                <td colSpan={2}>Loading...</td>
                            </tr>
                        ) : networks && isSuccess ?
                            networks.data.map((network: any) => (
                                <tr key={network.id}>
                                    <td>{network.name}</td>
                                </tr>
                            )) : null
                    }
                </tbody>
                <tfoot>
                    {
                        networks && networks?.data.length > 0 && (
                            <tr>
                                <td scope="row" colSpan={2}>
                                    <PaginationButtons
                                        backDisabled={page === 0 || !networks.previousLink || isFetching}
                                        nextDisabled={!networks.nextLink || isFetching}
                                        onBackClick={handlePrevPage}
                                        onNextClick={handleNextPage}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tfoot>
            </Table>
        </section>
    )
}

export default NetworkList
