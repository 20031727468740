import React from 'react'
import { FieldConfig } from '../../../../types/FieldConfig'

interface Props {
  field: string
  config: FieldConfig
  resource: any
  children?: React.ReactNode
}

const FieldVisibilityWrapper: React.FC<Props> = ({
  field,
  config,
  resource,
  children,
}) => {
  const ShowField = (field: string, config: FieldConfig, resource: any) => {
    const visibility = config[field].visibility
    switch (visibility) {
      case 'never':
        return false
      case 'always':
        return true
      case 'conditional':
        return resource && resource[field]
      default:
        return false
    }
  }
  return ShowField(field, config, resource) ? <>{children}</> : null
}

export default FieldVisibilityWrapper
