import { differenceInCalendarYears, parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { companyMessage } from '../assets/i18n/i18n'
import { CodeableConcept } from '../types/FHIRTypes/CodeableConcept'
import { HumanName } from '../types/FHIRTypes/HumanName'
import humanizeString from 'humanize-string'
import { Extension } from '../types/FHIRTypes/Extension'
import { Identifier } from '../types/FHIRTypes/Identifier'
import { Reference } from '../types/FHIRTypes/Reference'
import { getDataAbsentReason } from './fhirUtils/getDataAbsentReason'

export const ifExists = (data: any, key: string): string => {
  if (data) {
    return data[0][key]
  }
  return ''
}

export const numberWithCommas = (x: number): string => x !== undefined ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''

export const calculateAge = (dob: Date): number => {
  const today = new Date()
  const birthDate = new Date(dob)
  let age = differenceInCalendarYears(today, birthDate)
  const monthDifference = today.getMonth() - birthDate.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }
  return age
}

export const prepareValueForDb = (string: string): string | null => string || null

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url)
  } catch (error) {
    return false
  }

  return true
}

export const capitalizeFirstLetter = (string: string | undefined): string => {
  let newString = ''
  if (string) {
    newString = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  return newString
}

export const formatDate = (date: string): string => date ? format(new Date(date), 'MM/dd/yyyy') : ''

export const formatDateWithFormat = (date: string, fmt: string): string => date ? format(new Date(date), fmt) : ''

export const formatDateInTimeZone = (date: string, fmt: string, tz: string): string => format(parseISO(date), fmt, { timeZone: tz })

export const sum = (a: number, b: number): number => a + b

export const getUrlParameter = (name: string, url: string): string => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const formatDollar = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return formatter.format(value)
}

export function buildPhone(providerPhone: any): Array<string> {
  const phone = providerPhone
  if (phone?.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
  return phone
}

export const determineEnvironment = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://18.233.54.217:9090/fhir/metadata' : process.env.REACT_APP_ENVIRONMENT === 'quality' ? 'http://44.193.85.216:9090/fhir/metadata' : 'https://cchp.healthlx.com:9090/fhir/metadata'

export const determineEnvironmentProvider = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'https://18.233.54.217:9091/fhir/metadata' : process.env.REACT_APP_ENVIRONMENT === 'quality' ? 'http://44.193.85.216:9091/fhir/metadata' : 'https://cchp.healthlx.com:9091/fhir/metadata'

export const getToken = (): string => {
  let token = ''
  const usertoken = sessionStorage.getItem('auth-token')
  if (usertoken) {
    token = JSON.parse(usertoken)
  }
  return token
}

export const testStringWithRegex = (str: string | undefined, regex: RegExp): boolean => str ? regex.test(str) : false

export const addCompanyPhoneMessage = (str: string): string => str ? str.replace('<phone>', companyMessage.ContactPhone) : ''

export const handleCodeableConcept = (codeableConcept: CodeableConcept | CodeableConcept[] | undefined): string => {
  if (!codeableConcept) return ''

  const displayValue = (codeableConcept: CodeableConcept): string =>
    codeableConcept.coding?.[0]?.display || codeableConcept.text || codeableConcept.coding?.[0]?.code || ''

  return Array.isArray(codeableConcept) ? codeableConcept.map(displayValue).join(', ') : displayValue(codeableConcept)
}

export const handleReference = (reference: Reference | Reference[] | undefined): string => {
  if (!reference) return ''

  const displayValue = (reference: Reference): string => 
    reference.display || reference.reference || reference.identifier?.value || ''

  return Array.isArray(reference) ? reference.map(displayValue).join(', ') : displayValue(reference)
}

export const handleExtension = (extension: Extension | Extension[] | undefined): string => {
  // we'll eventually need more delicate handling of different types of extensions but this can be a start so that we have something to display
  if (!extension) return ''

  const extractDisplay = (value: any, label = ''): string => {
    if (Array.isArray(value)) {
      return value.map(item => extractDisplay(item, label)).join(', ')
    } else if (typeof value === 'object' && value !== null) {
      return Object.entries(value)
        .map(([key, val]) => `${capitalizeFirstLetter(key)}: ${extractDisplay(val, key)}`)
        .join(', ')
    } else {
      return capitalizeFirstLetter(value.toString())
    }
  }

  const displayValue = (extension: Extension): string => {
    for (const key in extension) {
      if (extension.hasOwnProperty(key) && key.startsWith('value') && (extension as any)[key] !== undefined) {
        const value = (extension as any)[key]
        return typeof value === 'object' || Array.isArray(value)
          ? extractDisplay(value, key)
          : capitalizeFirstLetter(value.toString())
      }
    }
    return ''
  }

  return Array.isArray(extension) ? extension.map(displayValue).join(', ') : displayValue(extension)
}

export const handleIdentifier = (identifiers: Identifier[] | undefined): string => {
  if (!identifiers) return ''

  return identifiers
    .map((identifier) => {
      const system = identifier.system ? identifier.system : ''
      const type = identifier.type ? handleCodeableConcept(identifier.type) : ''
      const value = identifier.value ? identifier.value : ''

      return type ? `${type}: ${value}` : system ? `${system}: ${value}` : value
    })
    .join(', ')
}

export const buildPeriod = (period: { start?: string; end?: string } | undefined): string => {
  if (!period) return ''

  const start = period?.start ? formatDateInTimeZone(period?.start, 'MM/dd/yyyy', 'UTC') : null
  const end = period?.end ? formatDateInTimeZone(period?.end, 'MM/dd/yyyy', 'UTC') : null

  if (start && end) {
    return `${start} - ${end}`
  } else if (start) {
    return `${start} - Ongoing`
  } else if (end) {
    return `Unknown - ${end}`
  } else {
    return ''
  }
}

export const scrollToTop = (): void => window.scrollTo(0, 0)

export const buildHumanName = (names: HumanName[] | undefined): string => {
  if (!names) return ''

  const name = names.find((name) => name.use === 'official') || names[0]
  const prefix = name.prefix ? name.prefix.join(' ') + ' ' : ''
  const given = name.given ? name.given.join(' ') : ''
  const family = name.family || ''
  const suffix = name.suffix ? name.suffix.join(' ') : ''

  return `${prefix}${given} ${family} ${suffix}`.trim()
}

export const findExtensionByURI = (extensions: Extension[] | undefined, uri: string): Extension => {
  if (!extensions) return {} as Extension

  return extensions.find((extension) => extension.url === uri) || {} as Extension
}