import React, { FC, Fragment, useEffect } from 'react'
import { useState } from 'react'
import { Form, Alert, Button, Spinner } from 'react-bootstrap'
import PasswordInput from '../../../components/PasswordInput'
import InfoPopover from '../../../components/InfoPopover'


interface Props {
  formHandler: any;
  alertState: { show: boolean; variant: string; message: string; };
  isLoading: boolean;
  closeAlert: () => void;
  validateForgotPasswordCode: any;
  code: string | null;
}

const ChangePasswordForm: FC<Props> = ({ formHandler, alertState, closeAlert, isLoading, validateForgotPasswordCode, code }: Props) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showSecondPassword, setShowSecondPassword] = useState(false)


  useEffect(() => {
    validateForgotPasswordCode.mutate({ code })
  }, [])

  return (
    <>
      <h3 className='headerText'>Change Password</h3>
      {
        validateForgotPasswordCode.isError && (
          <Alert variant="danger" dismissible onClose={validateForgotPasswordCode.reset}>
            {validateForgotPasswordCode.error.response.text}
          </Alert>
        )
      }
      {
        alertState.show && (
          <Alert variant={alertState.variant} dismissible onClose={closeAlert}>
            {alertState.message}
          </Alert>
        )
      }
      {
        validateForgotPasswordCode.isSuccess && (
          <Form noValidate onSubmit={formHandler.handleSubmit} id='login'>

            <Form.Group className='custom'>
              <Form.Label>
                Password:
                <InfoPopover title='Password Requirements' content='Password must be a minimum of 16 characters. Please include at least 1 Uppercase letter, 1 Lowercase letter, 1 Number, or 1 Special Character.' />
              </Form.Label>
              <PasswordInput
                showPassword={showPassword}
                placeholder='Create Password'
                name='password'
                value={formHandler.values.password}
                handleChange={formHandler.handleChange}
                handleBlur={formHandler.handleBlur}
                isInvalid={!!formHandler.errors.password && formHandler.touched.password}
                toggleShowPassword={() => setShowPassword(!showPassword)}
                autoComplete='new-password'
              />
              {
                !!formHandler.errors.password && formHandler.touched.password && (
                  <Form.Text className='text-danger'>
                    {formHandler.errors.password}
                  </Form.Text>
                )
              }
            </Form.Group>
            <Form.Group className='custom mt-2'>
              <Form.Label>Enter Password Again:</Form.Label>
              <PasswordInput
                showPassword={showSecondPassword}
                placeholder='Repeat Password'
                name='passwordAgain'
                value={formHandler.values.passwordAgain}
                handleChange={formHandler.handleChange}
                handleBlur={formHandler.handleBlur}
                isInvalid={!!formHandler.errors.passwordAgain && formHandler.touched.passwordAgain}
                toggleShowPassword={() => setShowSecondPassword(!showSecondPassword)}
                autoComplete='new-password'
              />
              {
                !!formHandler.errors.passwordAgain && formHandler.touched.passwordAgain && (
                  <Form.Text className='text-danger'>
                    {formHandler.errors.passwordAgain}
                  </Form.Text>
                )
              }
            </Form.Group>
            <div>
              <Button variant='link' href='/login' className='mt-3 mr-3'>Go Back</Button>
              {!isLoading ?
                <Button type='submit' className='custom mt-3'>Submit</Button>
                :
                <Button type='submit' className='custom mt-3' disabled={isLoading}>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    size='sm'
                  />
                </Button>
              }
            </div>
          </Form>

        )
      }


    </>
  )
}

export default ChangePasswordForm