import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useUser(): any {

  return useQuery('user', async () => {
    const foundUser = await request.get(`${getApiServer()}/auth/user`).set('Accept', 'application/json').withCredentials()
    return foundUser.body
  }, {
    staleTime: 3600000,
    cacheTime: 3600000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: false
  })
}