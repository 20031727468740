import React from 'react'
import { Container, Jumbotron } from 'react-bootstrap'

const PageNotFound = () => {
    return (
        <main>
        <Container className='text-center'>
            <Jumbotron>
                <h2 className='h1'>404: Page Not Found</h2>
                <p>The URL you requested was not found. Please <a href='/'>return to the Home Page</a></p>
            </Jumbotron>
        </Container>
        </main>
    )
}

export default PageNotFound