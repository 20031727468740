import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useProviders(searchTerm = '', searchId = ''): any {

  return useQuery(['Provider', searchTerm, searchId], async () => {
    return await request.get(`${getApiServer()}/providers`).query({ searchTerm, searchId }).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    initialData: null,
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}