import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Spinner } from 'react-bootstrap'
import useQuery from '../../../hooks/location/useQuery'
import '../../../App.scss'
import Loader from '../../Loader'

interface Props {
  memberDetails: any;
  goBack: () => void;
  validateResults: boolean;
  isLoading: boolean;
  isError: boolean;
  error: string;
  sendNewCodeResult: any;
  sendNewCode: () => void;
  onSubmit: (values: any) => void;
}

const ValidateEmail: FC<Props> = ({ memberDetails, isError, error, validateResults, sendNewCodeResult, sendNewCode, onSubmit, isLoading }: Props) => {
  const [showAlertState, setShowAlertState] = useState({ show: true, variant: 'info', message: '' })
  const [newCodeClicked, setNewCodeClicked] = useState(false)

  const query = useQuery()
  const code = query.get('code')
  useEffect(() => {
    if (code) {
      onSubmit({
        code
      })
    }
  }, [code])

  useEffect(() => {
    if (validateResults === false || isError) {
      setShowAlertState({
        show: true,
        variant: 'danger',
        message: isError ? error : 'Unable to verify email.'
      })
    }
  }, [validateResults, isError])

  useEffect(() => {
    if (newCodeClicked && sendNewCodeResult.isSuccess) {
      setShowAlertState({
        show: true,
        variant: 'success',
        message: 'Sent new code'
      })
    }
  }, [newCodeClicked, sendNewCodeResult.isSuccess])

  const handleSendNewCode = () => {
    setNewCodeClicked(true)
    sendNewCode()
  }

  if (isLoading) {
    return <Loader
      message="Validating email..."
    />
  }

  return (
    <>
      <h4>Email Validation</h4>
      {
        !isLoading ? showAlertState.show && (
          <Alert variant={showAlertState.variant}>
            {
              isError ? <span dangerouslySetInnerHTML={{ __html: showAlertState.message }} /> : showAlertState.message || `Please check the email sent to ${memberDetails.email} for verifying the email you entered.`
            }
            {
              showAlertState.message === 'Unable to verify email.' && (
                <Button onClick={handleSendNewCode} variant="outline-danger" className='ml-3'>
                  {
                    sendNewCodeResult.isLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        size='sm'
                        aria-hidden='true'
                      />
                    ) : 'Send New Code'
                  }
                </Button>
              )
            }
          </Alert>
        ) : null
      }
    </>
  )
}

export default ValidateEmail