import { capitalizeFirstLetter, handleReference } from '../helpers'
import { CareTeamBundle } from '../../types/FHIRTypes/Bundle'

export const formatCareTeamDataForTable = (careTeams: CareTeamBundle): { careTeamId: string; subject: string; status: string; }[] => {
  return careTeams?.entry?.filter(({ resource }) => resource.resourceType === 'CareTeam')
    .map(({ resource }) => ({
      careTeamId: resource.id,
      subject: handleReference(resource.subject),
      status: capitalizeFirstLetter(resource.status),
    })) || []
}