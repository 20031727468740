import { isAfter, isWithinInterval, parseISO } from 'date-fns'
import { capitalizeFirstLetter, handleCodeableConcept } from '../helpers'
import { Coverage } from '../../types/FHIRTypes/Coverage'
import humanizeString from 'humanize-string'

export function calculatedStatus(resource: Coverage): string {
  if (!resource.period) {
    return 'N/A'
  } else {
    const { start, end } = resource.period
    const today = new Date()

    if (start && !end) {
      return isAfter(today, parseISO(start)) ? 'Active' : 'N/A'
    } else if (start && end) {
      return isWithinInterval(today, { start: parseISO(start), end: parseISO(end) }) ? 'Active' : 'Inactive'
    } else {
      return 'N/A'
    }
  }
}

export function buildCoverageMemberID(resource: Coverage, isSingleTenantServer: any): string {
  if (isSingleTenantServer) {
    return getBeneficiaryReference(resource)
  }

  return getIdentifierValue(resource)
}

function getBeneficiaryReference(resource: Coverage): string {
  if (resource?.beneficiary?.reference) {
    const parts = resource.beneficiary.reference.split('/')
    return parts.length > 1 ? parts[1] : ''
  }
  return ''
}

function getIdentifierValue(resource: Coverage): string {
  if (resource?.identifier?.[0]?.value) {
    return resource.identifier[0].value
  }
  return 'N/A'
}

export const sortCoveragesByStartDate = (coverages: Coverage[]): Coverage[] => {
  return coverages.sort((a, b) => {
    const startDateA = a.period?.start ? new Date(a.period.start).getTime() : 0
    const startDateB = b.period?.start ? new Date(b.period.start).getTime() : 0
    return startDateB - startDateA
  })
}

export const getClass = (classType: any) => {
  return classType.coding ? classType.coding[0].code : classType.text
}

export const processCoverageClass = (coverageClass: any[]) => {
  const order = ['group', 'subgroup', 'plan', 'subplan', 'class', 'subclass', 'sequence', 'rxbin', 'rxpcn', 'rxid', 'rxgroup']
  const alwaysShow = ['group', 'plan']

  const sortedCoverageClass = coverageClass
    .sort((a, b) => {
      const aType = getClass(a.type)
      const bType = getClass(b.type)
      const aIndex = order.indexOf(aType)
      const bIndex = order.indexOf(bType)

      if (aIndex === -1 && bIndex === -1) return 0
      if (aIndex === -1) return 1
      if (bIndex === -1) return -1
      return aIndex - bIndex
    })
    .map((c) => ({
      type: capitalizeFirstLetter(getClass(c.type)),
      value: c.value === c.name ? humanizeString(c.value) : `${humanizeString(c.value)} ${c.name}`
    }))

  alwaysShow.forEach((type) => {
    if (!sortedCoverageClass.some((c) => c.type.toLowerCase() === type)) {
      sortedCoverageClass.push({
        type: capitalizeFirstLetter(type),
        value: 'N/A'
      })
    }
  })

  return sortedCoverageClass
}