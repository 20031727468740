import React, { FC } from 'react'
import { Agent } from '../../../../types/FHIRTypes/Provenance'
import { handleCodeableConcept } from '../../../../utils/helpers'
import './index.scss'

interface AgentProps {
    agents: Agent[]
}

const AgentResource: FC<AgentProps> = ({ agents }) => {

    const authorData = agents.find(agent => agent?.type?.coding?.[0]?.code === 'author')
    const transmitterData = agents.find(agent => agent?.type?.coding?.[0]?.code === 'transmitter')

    const authorReference = authorData?.who?.reference || ''
    const transmitterReference = transmitterData?.who?.reference || ''

    const otherAgents = agents.filter(agent => {
        const agentType = agent?.type?.coding?.[0]?.code
        return agentType !== 'author' && agentType !== 'transmitter'
    })

    return (
        <div className="agent-info">
            <p>
                <span>{'Author: '}</span>
                <span>{authorReference}</span>
            </p>
            <p>
                <span>{'Transmitter: '}</span>
                <span>{transmitterReference}</span>
            </p>
            {otherAgents.map((agent, index) => (
                <p key={`otherAgent-${index}`}>
                    <span>{agent?.type ? handleCodeableConcept(agent.type) : ''}: </span>
                    <span>{agent?.who ? agent?.who?.reference : ''}</span>
                </p>
            ))}
        </div>
    )
}

export default AgentResource