import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { capitalizeFirstLetter, formatDateInTimeZone, handleCodeableConcept, handleReference } from '../../../../../utils/helpers'
import { Activity, Detail } from '../../../../../types/FHIRTypes/CarePlan'
import { getScheduledTimeData } from '../../../../../utils/fhirUtils/timing'

interface Props {
  activity: Activity
}

const CarePlanActivityDetail: FC<Props> = ({ activity }) => {
  const details: Detail[] = activity.detail ? [activity.detail] : []

  const getSchedule = (detail: Detail) => {
    if (detail.scheduledTiming) {
      const renderScheduledTime = (detail: Detail) => {
        const scheduledTimeData = getScheduledTimeData(detail.scheduledTiming)

        if (scheduledTimeData.type === 'event' || scheduledTimeData.type === 'code' || scheduledTimeData.type === 'period' || scheduledTimeData.type === 'string') {
          return <dd>{scheduledTimeData.data}</dd>
        }

        if (scheduledTimeData.type === 'repeat') {
          const { boundsDescription, countDescription, durationDescription, frequencyDescription, periodDescription, dayOfWeek, timeOfDay, whenDescription, } = scheduledTimeData.data

          return (
            <Card>
              <Card.Header>
                <div>
                  <p>Repeat</p>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  {[
                    { label: 'Bounds', value: boundsDescription },
                    { label: 'Count', value: countDescription },
                    { label: 'Duration', value: durationDescription },
                    { label: 'Frequency', value: frequencyDescription },
                    { label: 'Period', value: periodDescription },
                    { label: 'Day of Week', value: dayOfWeek },
                    { label: 'Time of Day', value: timeOfDay },
                    { label: 'When', value: whenDescription },
                  ]
                    .filter(property => property.value).map((property, index) => (
                      <React.Fragment key={index}>
                        <Col sm={3}>
                          <dt>{property.label}</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>{property.value}</dd>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              </Card.Body>
            </Card>
          )
        }

        return null
      }
      return renderScheduledTime(detail)
    }

    if (detail.scheduledPeriod?.start || detail.scheduledPeriod?.end) {
      return detail.scheduledPeriod.start ? detail.scheduledPeriod.end ? `${formatDateInTimeZone(detail.scheduledPeriod.start, 'MM/dd/yyyy', 'UTC')} - ${formatDateInTimeZone(detail.scheduledPeriod.end, 'MM/dd/yyyy', 'UTC')}` : formatDateInTimeZone(detail.scheduledPeriod.start, 'MM/dd/yyyy', 'UTC') : 'N/A'
    }

    if (detail.scheduledString) {
      return detail.scheduledString
    }
  }

  const getProduct = (detail: Detail) => {
    if (detail.productCodeableConcept) {
      return handleCodeableConcept(detail.productCodeableConcept)
    }
    if (detail.productReference) {
      return handleReference(detail.productReference)
    }
    return ''
  }

  return (
    <>
      {details.map((detail, index) => (
        <Card key={`care-plan-activity-detail-${index}`} className='mt-3 w-100'>
          <Card.Header>
            <div>
              <p>Details</p>
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <dt>Kind</dt>
              </Col>
              <Col sm={9}>
                <dd>{capitalizeFirstLetter(detail.kind)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Code</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.code ? handleCodeableConcept(detail.code) : ''}</dd>
              </Col>
              <Col sm={3}>
                <dt>Reason Code</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.reasonCode?.map((reason) => handleCodeableConcept(reason)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Reason Reference</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.reasonReference?.map((reasonReference) => handleReference(reasonReference)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Goal</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.goal?.map((goal) => handleReference(goal)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Status</dt>
              </Col>
              <Col sm={9}>
                <dd>{capitalizeFirstLetter(detail.status)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Status Reason</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.statusReason ? handleCodeableConcept(detail.statusReason) : ''}</dd>
              </Col>
              <Col sm={3}>
                <dt>Do Not Perform</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.doNotPerform ? 'Yes' : 'No'}</dd>
              </Col>
              <Col sm={3}>
                <dt>Scheduled</dt>
              </Col>
              <Col sm={9}>
                <dd>{getSchedule(detail)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Location</dt>
              </Col>
              <Col sm={9}>
                <dd>{handleReference(detail.location)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Performer</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.performer?.map((performer) => handleReference(performer)).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Product</dt>
              </Col>
              <Col sm={9}>
                <dd>{getProduct(detail)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Daily Amount</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.dailyAmount?.value} {detail.dailyAmount?.unit}</dd>
              </Col>
              <Col sm={3}>
                <dt>Quantity</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.quantity?.value} {detail.quantity?.unit}</dd>
              </Col>
              <Col sm={3}>
                <dt>Description</dt>
              </Col>
              <Col sm={9}>
                <dd>{detail.description}</dd>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </>
  )
}

export default CarePlanActivityDetail