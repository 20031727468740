import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { buildPatientId } from '../../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone } from '../../../../../utils/helpers'
import { sortImmunizations } from '../../../../../utils/fhirUtils/sortImmunizations'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import PaginationButtons from '../../../../PaginationButtons'



interface Props {
  patientImmunizations: any;
  patientData: any;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const ImmunizationList: FC<Props> = ({ patientData, patientImmunizations, isFetching, handleChangePage }: Props) => {
  // const immunization = immunizationData && immunizationData.entry ? immunizationData.entry.find((i: any) => i.resource.id === detailId) : ''
  const immunizations = patientImmunizations && patientImmunizations.entry ? patientImmunizations.entry.map((i: any) => i.resource) : []

  const getImmunizationStatus = (item: any) => {
    return item?.status || ''
  }

  const getImmunizationOccurrence = (item: any) => {
    return item?.occurrenceDateTime ? formatDateInTimeZone(item.occurrenceDateTime, 'MM/dd/yyyy', 'UTC') : ''
  }
  const nextLink = patientImmunizations && patientImmunizations?.link.find((l: any) => l.relation === 'next')
  const previousLink = patientImmunizations && patientImmunizations?.link.find((l: any) => l.relation === 'previous')

  return (
    <>
      {
        patientImmunizations && patientImmunizations.total > 0 && (
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {patientImmunizations.total > 0 &&
              <div className='mb-2'>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </div>
            }
            {`${patientImmunizations.total.toLocaleString()} record(s) found`}
          </div>
        )
      }

      <Table size='sm' hover>
        <thead>
          <tr>
            <th className='d-table-cell'>Code</th>
            <th className='d-none d-lg-table-cell'>Status</th>
            <th className='d-none d-lg-table-cell'>Occurence</th>
            <th className='d-none d-lg-table-cell'>View Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Member Immunizations...</span>
                </td>
              </tr>
            ) : patientImmunizations?.total === 0 ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                  No Immunizations found for this member.
                </td>
              </tr>
            ) : (
              <>
                {
                  immunizations.map((immunization: any) => (
                    <tr key={immunization.id}>
                      <td className='d-table-cell'><Link to={`/patient/Immunization/${immunization.id}`}>{handleCodeableConcept(immunization.vaccineCode)}</Link></td>
                      <td className='d-none d-lg-table-cell'>{getImmunizationStatus(immunization)}</td>
                      <td className='d-none d-lg-table-cell'>{getImmunizationOccurrence(immunization)}</td>
                      <td className='d-none d-lg-table-cell'><Link to={`/patient/Immunization/${immunization.id}`}>View Detail</Link></td>
                    </tr>
                  ))
                }
              </>
            )

          }
        </tbody>
        <tfoot>
          {patientImmunizations && patientImmunizations.total > 0 &&
            <tr>
              <td scope="row" colSpan={5}>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </td>
            </tr>
          }
        </tfoot>

      </Table>
    </>
  )
}

export default ImmunizationList