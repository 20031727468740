import React from 'react'
import { useHistory } from 'react-router-dom'

interface PermissionGateProps {
    children: JSX.Element,
    user: any,
    role: string
}

const PermissionGate: React.FC<PermissionGateProps> = ({ children, user, role }) => {
    const userRole = user?.role || ''
    const hasPermission = userRole === role

    const history = useHistory()

    if (!hasPermission) {
        history.push('/')
        return null
    }

    return children
}

export default PermissionGate