import React from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'

import customMessage from '../customizations/customMessage.json'



export const companyMessage = customMessage



export const companyNameFull = <FormattedMessage id="CompanyNameFull" defaultMessage={'Health LX'} />
export const companyAbbreviation = <FormattedMessage id="CompanyAbbreviation" defaultMessage={'HLX'} />
export const companyDataDescription = <FormattedMessage id="CompanyDataDescription" defaultMessage={''} />
export const preferedName = <FormattedMessage id="PreferedName" defaultMessage={'HLX'} />
export const dataPortalTitle = <FormattedMessage id="DataPortalTitle" defaultMessage={'Member Data'} />
export const contactLink = <FormattedMessage id="ContactLink" defaultMessage={'https://www.healthlx.com/'} />
export const contactEmail = <FormattedMessage id="ContactEmail" defaultMessage={'https://www.healthlx.com/contact-us/'} />
export const contactPhone = <FormattedMessage id="ContactPhone" defaultMessage={'https://www.healthlx.com/about-us/'} />
export const serverType = <FormattedMessage id="ServerType" defaultMessage={'FHIR'} />
export const contactHours = <FormattedMessage id="ContactHours" defaultMessage={'9:00 - 5:00'} />



// export const i18nConstants = {
//     companyNameFull: <FormattedMessage id="CompanyNameFull" defaultMessage={'Health LX'} />,
//     companyAbbreviation: <FormattedMessage id="CompanyAbbreviation" defaultMessage={'HLX'} />,
//     dataPortalTitle: <FormattedMessage id="DataPortalTitle" defaultMessage={'HLX'} />,
//     contactLink: <FormattedMessage id="ContactLink" defaultMessage={'HLX'} />,
//     contactEmail: <FormattedMessage id="ContactEmail" defaultMessage={'HLX'} />,
//     contactPhone: <FormattedMessage id="ContactPhone" defaultMessage={'HLX'} />,
//     serverType: <FormattedMessage id="ServerType" defaultMessage={'test1'} />,
// }