import { capitalizeFirstLetter, handleCodeableConcept } from '../helpers'
import { CarePlanBundle } from '../../types/FHIRTypes/Bundle'
import { CarePlan, Activity } from '../../types/FHIRTypes/CarePlan'
import { buildPeriod } from '../helpers'

export function buildCarePlanActivityOutcome(resource: CarePlan): string {
  const activityOutcome = resource.activity?.map((activity) => activity.outcomeCodeableConcept?.map((outcome) => handleCodeableConcept(outcome)).join(', ')) || 'N/A'

  return activityOutcome[activityOutcome.length - 1] || activityOutcome[0] || 'N/A'
}

export function buildCarePlanStatus(resource: CarePlan): string {
  const carePlanStatus = resource.status ? capitalizeFirstLetter(resource.status) : ''
  return carePlanStatus
}

export const formatCarePlanDataForTable = (carePlans: CarePlanBundle): { carePlanId: string; status: string; period: string; }[] => {
  return carePlans?.entry?.filter(({ resource }) => resource.resourceType === 'CarePlan')
    .map(({ resource }) => ({
      carePlanId: resource.id,
      status: buildCarePlanStatus(resource),
      period: resource.period ? buildPeriod(resource.period) : '',
    })) || []
}