
import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { ChangePasswordSchema } from '../../../utils/validation'
import PasswordInput from '../../PasswordInput'
import InfoPopover from '../../InfoPopover'
import { UseMutationResult } from 'react-query'
import { CustomHttpError } from '../../../types/CustomHttpError'

interface Props {
  initialValues: { password: string; passwordAgain: string, currentPassword: string };
  changePassword: UseMutationResult<any, CustomHttpError, any, any>;
  userId: string;
}

const initialAlertState = {
  show: false,
  variant: 'success',
  message: '',
}

const ChangePassword: FC<Props> = ({
  initialValues,
  changePassword,
  userId
}: Props) => {
  const [alertState, setAlertState] = useState(initialAlertState)

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showSecondPassword, setShowSecondPassword] = useState(false)


  useEffect(() => {

    if (changePassword.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully changed your password',
      })
    }

    if (changePassword.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Error: ',
      })
    }
  }, [changePassword.isSuccess, changePassword.isError])

  const formHandler = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      changePassword.mutate({
        oldPassword: values.currentPassword,
        password: values.password,
        userId,
      })
    },
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true,
  })

  return (
    <div className="dataContainer mb-3">
      <Row>
        <Col xs={12}>
          <Alert
            show={alertState.show}
            variant={alertState.variant}
            dismissible
            onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}
          >
            {alertState.message}
            {changePassword.isError && (
              <div
                dangerouslySetInnerHTML={{
                  __html: changePassword.error?.response.text,
                }}
              />
            )}
          </Alert>
        </Col>
        <Col xs={12}>
          <Form noValidate onSubmit={formHandler.handleSubmit}>
            <Form.Group>
              <Row className="mt-3 memberRow">
                <Col md={4} sm={12}>
                  <Form.Label>
                    Current Password
                  </Form.Label>
                </Col>
                <Col md={8} sm={12}>
                  <PasswordInput
                    showPassword={showCurrentPassword}
                    placeholder='Current Password'
                    name='currentPassword'
                    value={formHandler.values.currentPassword}
                    handleChange={formHandler.handleChange}
                    handleBlur={formHandler.handleBlur}
                    isInvalid={Boolean(!!formHandler.errors.currentPassword && formHandler.touched.currentPassword)}
                    toggleShowPassword={() => setShowCurrentPassword(!showCurrentPassword)}
                    autoComplete='current-password'
                  />
                  {
                    Boolean(!!formHandler.errors.currentPassword && formHandler.touched.currentPassword) && (
                      <Form.Text className='text-danger'>
                        {formHandler.errors.currentPassword}
                      </Form.Text>
                    )
                  }
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row className="mt-3 memberRow">
                <Col md={4} sm={12}>
                  <Form.Label>
                    New Password
                    <InfoPopover title='Password Requirements' content='Password must be a minimum of 16 characters. Please include at least 1 Uppercase letter, 1 Lowercase letter, 1 Number, or 1 Special Character.' />
                  </Form.Label>
                </Col>
                <Col md={8} sm={12}>
                  <PasswordInput
                    showPassword={showPassword}
                    placeholder='New Password'
                    name='password'
                    value={formHandler.values.password}
                    handleChange={formHandler.handleChange}
                    handleBlur={formHandler.handleBlur}
                    isInvalid={Boolean(!!formHandler.errors.password && formHandler.touched.password)}
                    toggleShowPassword={() => setShowPassword(!showPassword)}
                    autoComplete='new-password'
                  />
                  {
                    Boolean(!!formHandler.errors.password && formHandler.touched.password) && (
                      <Form.Text className='text-danger'>
                        {formHandler.errors.password}
                      </Form.Text>
                    )
                  }
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row className="mt-3 memberRow">
                <Col md={4} sm={12}>
                  <Form.Label>Confirm New Password</Form.Label>
                </Col>
                <Col md={8} sm={12}>
                  <PasswordInput
                    showPassword={showSecondPassword}
                    placeholder='Confirm New Password'
                    name='passwordAgain'
                    value={formHandler.values.passwordAgain}
                    handleChange={formHandler.handleChange}
                    handleBlur={formHandler.handleBlur}
                    isInvalid={Boolean(!!formHandler.errors.passwordAgain && formHandler.touched.passwordAgain)}
                    toggleShowPassword={() => setShowSecondPassword(!showSecondPassword)}
                    autoComplete='new-password'
                  />
                  {
                    Boolean(!!formHandler.errors.passwordAgain && formHandler.touched.passwordAgain) && (
                      <Form.Text className='text-danger'>
                        {formHandler.errors.passwordAgain}
                      </Form.Text>
                    )
                  }
                </Col>
              </Row>
            </Form.Group>
            <Col xs={12} className="mt-3">
              <Button
                className="float-right app-button"
                type="submit"
                disabled={changePassword.isLoading}
              >
                {changePassword.isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    aria-hidden="true"
                  />
                ) : (
                  'Change'
                )}
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default ChangePassword
