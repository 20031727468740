import React, { FC, useEffect } from 'react'
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap'
import ChangePassword from '../../../components/Member/ChangePassword'
import useChangePassword from '../../../hooks/authentication/useChangePassword'
import useUser from '../../../hooks/authentication/useUser'
import isAuthenticated from '../../../routes/PrivateRoute'
import { useRole } from '../../../hooks/authentication/useRole'
import useAccount from '../../../hooks/admin/useAccount'


const initialPasswordValues = {
  currentPassword: '',
  password: '',
  passwordAgain: ''
}

const initialAlertState = {
  show: false,
  variant: 'success',
  message: ''
}

const MemberAccount: FC = () => {
  const { data: user } = useUser()
  const { data: accountInfo, refetch: refetchAccount } = useAccount(user?.pid)
  const userChangePassword = useChangePassword()

  const { role } = useRole()

  useEffect(() => {
    if (role === 'member') {
      refetchAccount()
    }
  }, [role])
  return (
    <main className='content'>
      <Container>
        {/* <Breadcrumb>
        <Breadcrumb.Item href={homeLink.destination}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>My Account</Breadcrumb.Item>
      </Breadcrumb> */}
        <Row>
          <Col sm={6}>
            <h2 className='headerText'>My Account</h2>
          </Col>
          <Col sm={12}>
            <section className='dataContainer'>
              <dl>
                <Row>
                  <Col sm={3}>
                    <dt>Username</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {user.sub}
                    </dd>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <dt>Email</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {user?.email}
                    </dd>
                  </Col>
                </Row>
                {
                  role === 'member' && (
                    <>
                      <Row>
                        <Col sm={3}>
                          <dt>Account ID</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {accountInfo?.memberId}
                          </dd>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <dt>Proxy</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {accountInfo?.isProxy ? 'Y' : 'N'}
                          </dd>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <dt>Proxy Name</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {accountInfo?.proxyFirstName || ''} {accountInfo?.proxyLastName || ''}
                          </dd>
                        </Col>
                      </Row>
                    </>
                  )
                }
                <Accordion as={Row}>
                  <Col sm={3}>
                    <dt>Change Password</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      <Accordion.Toggle as={Button} eventKey='0' className='app-button'>
                        Change Password
                      </Accordion.Toggle>
                    </dd>
                  </Col>
                  <Col sm={12}>
                    <Accordion.Collapse eventKey="0">
                      <Container>
                        <ChangePassword
                          userId={user.sub}
                          changePassword={userChangePassword}
                          initialValues={initialPasswordValues}
                        />
                      </Container>
                    </Accordion.Collapse>
                  </Col>
                </Accordion>
              </dl>
            </section>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default isAuthenticated(MemberAccount)