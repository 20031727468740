import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useApp(appId: string): any {
  return useQuery(['app', appId], async () => {
    return await request.get(`${getApiServer()}/apps/${appId}`).set('Accept', 'application/json').then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    enabled: false
  })
}