import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { CareTeamBundle } from '../../../../../types/FHIRTypes/Bundle'
import { formatCareTeamDataForTable } from '../../../../../utils/fhirUtils/careTeam'
import PaginationButtons from '../../../../PaginationButtons'

interface Props {
  careTeamData: CareTeamBundle;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const CareTeamList: FC<Props> = ({ careTeamData, handleChangePage, isFetching }: Props) => {
  const patientCareTeams = formatCareTeamDataForTable(careTeamData)

  const nextLink = careTeamData?.link?.find((l: any) => l.relation === 'next')
  const previousLink = careTeamData?.link?.find((l: any) => l.relation === 'previous')

  return (
    <>
      <div className="d-flex flex-column mb-3 font-primary-color font-weight-bold">
        {patientCareTeams.length > 0 && (
          <div className="mb-2">
            <PaginationButtons
              backDisabled={!previousLink}
              nextDisabled={!nextLink}
              onBackClick={() =>
                previousLink && handleChangePage(previousLink.url)
              }
              onNextClick={() => nextLink && handleChangePage(nextLink.url)}
            />
          </div>
        )}
        {careTeamData?.total > 0
          ? `${careTeamData.total.toLocaleString()} record(s) found`
          : ''}
      </div>
      <Table size="sm" hover>
        <thead>
          <tr>
            <th className="d-table-cell">Care Team Id</th>
            <th className="d-none d-lg-table-cell">Status</th>
            <th className="d-table-cell">Subject</th>
            <th className="d-none d-lg-table-cell">View Detail</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td colSpan={6} rowSpan={15} className="d-flex">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
                <span style={{ marginLeft: '10px' }}>
                  Loading Care Team Data...
                </span>
              </td>
            </tr>
          ) : patientCareTeams.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center">
                No Care Teams found for this member.
              </td>
            </tr>
          ) : (
            patientCareTeams.map((careTeam: any) => (
              <tr key={careTeam.careTeamId}>
                <td className='d-table-cell'><Link to={`/patient/CareTeam/${careTeam.careTeamId}`}>{careTeam.careTeamId}</Link></td>
                <td className='d-table-cell'>{careTeam.status}</td>
                <td className='d-table-cell'>{careTeam.subject}</td>
                <td className="d-none d-lg-table-cell">
                  <Link to={`/patient/CareTeam/${careTeam.careTeamId}`}>
                    View Detail
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          {patientCareTeams.length > 0 && (
            <tr>
              <td scope="row" colSpan={5}>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </td>
            </tr>
          )}
        </tfoot>
      </Table>
    </>
  )
}

export default CareTeamList