import React, { FC } from 'react'
import { ContactPoint } from '../../../../types/FHIRTypes/ContactPoint'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface TelecomProps {
    contactPoint: ContactPoint;
}

const Telecom: FC<TelecomProps> = ({ contactPoint }) => {
    const getTelecom = () => {
        const useType = contactPoint.use ? `${capitalizeFirstLetter(contactPoint.use)}: ` : ''
        return `${capitalizeFirstLetter(contactPoint.system)}: ${useType} ${contactPoint.value}`
    }

    return (
        <div>
            {getContentOrFallback(getTelecom())}
        </div>
    )
}

export default Telecom