import React, { FC, useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import { useFormik } from 'formik'
import { appConfig } from '../../../assets/customizations/config'
import { Link } from 'react-router-dom'
import { isBefore, sub } from 'date-fns'
import { MemberSchema } from '../../../utils/validation'

interface Props {
  initialValues: any
  memberType: string
  validateResults: any
  isLoading: boolean
  isError: boolean
  error: string
  onSubmit: (values: any) => void,
  validatedMember: any
}

const Member: FC<Props> = ({ initialValues, validateResults, isLoading, isError, error, memberType, onSubmit, validatedMember }) => {
  const [showError, setShowError] = useState(false)
  const [showDuplicateError, setShowDuplicateError] = useState(false)

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      memberType,
    },
    validationSchema: MemberSchema,
    onSubmit: (values) => {
      onSubmit({
        memberId: values.memberId,
        dob: values.dob,
        ssn: values.ssn,
        lastname: values.memberLastName,
      })
    },
    enableReinitialize: true
  })

  useEffect(() => {
    validatedMember.reset()
    setShowError(false)
    setShowDuplicateError(false)
  }, [])

  useEffect(() => {
    if (validateResults) {
      if (validateResults.verified === false) {
        setShowError(true)
      }

      if (validateResults.duplicateAccount) {
        setShowDuplicateError(true)
      }
    }

    if (isError) {
      setShowError(true)
    }
  }, [isError, validateResults])

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className='formContainer'>
      <h4>Member Information</h4>
      {showError && (
        <Alert variant='danger' dismissible onClose={() => setShowError(false)}>
          {error ? (
            <div dangerouslySetInnerHTML={{ __html: error }} />
          ) : 'We were not able to verify this member in our system based on the information provided. If you need to locate your Member ID, please call one of the support numbers'
          }
        </Alert>
      )}
      {showDuplicateError && (
        <Alert variant='danger' dismissible onClose={() => setShowDuplicateError(false)}>
          Account already exists. You can try resetting your password or can <Link to='/contact'>contact us</Link> with any questions or concerns.
        </Alert>
      )}
      <Form.Group>
        <Form.Label>Member ID:</Form.Label>
        <Form.Control
          type='text'
          name='memberId'
          value={formik.values.memberId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.memberId && !!formik.touched.memberId}
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.memberId}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Member Last Name:</Form.Label>
        <Form.Control
          type='text'
          name='memberLastName'
          value={formik.values.memberLastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={!!formik.errors.memberLastName && !!formik.touched.memberLastName}
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.memberLastName}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mt-3'>
        {appConfig.use_dob_flag && (
          <>
            <Form.Label>Member Date of Birth:</Form.Label>
            <Form.Control
              as={MaskedInput}
              mask='11/11/1111'
              placeholder='MM/DD/YYYY'
              name='dob'
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.dob && !!formik.touched.dob}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.dob}{' '}
              {memberType === 'proxy' && isBefore(new Date(formik.values.dob), sub(new Date(), { years: 18 })) && (
                <>
                  If an exception is needed, please <Link to='/contact'>contact us</Link>.
                </>
              )}
            </Form.Control.Feedback>
          </>
        )}
      </Form.Group>
      <Form.Group className='mt-3'>
        {appConfig.use_ssn_flag && (
          <>
            <Form.Label>Last 4 Digits of SSN:</Form.Label>
            <Form.Control
              type='text'
              name='ssn'
              value={formik.values.ssn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.ssn && !!formik.touched.ssn}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.ssn}
            </Form.Control.Feedback>
          </>
        )}
      </Form.Group>
      <Link to='/register/requestor' className='btn btn-light mr-3'>
        &lt;&lt; Back
      </Link>
      <Button className='custom' type='submit' disabled={isLoading}>
        {isLoading ? (
          <Spinner
            as='span'
            animation='border'
            role='status'
            size='sm'
            aria-hidden='true'
          />
        ) : 'Next >>'}
      </Button>
    </Form>
  )
}

export default Member
