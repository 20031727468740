import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ProviderResource } from '../../../../../types/ProviderResource'
import { capitalizeFirstLetter } from '../../../../../utils/helpers'

interface OrganizationDetailProps {
    organization: ProviderResource | null
}

const buildLocationAddress = (address: any) => `${address.line.join(', ')}, ${address.city}, ${address.state} ${address.postalCode}`

const OrganizationDetail: React.FC<OrganizationDetailProps> = ({ organization }) => {

    const getOrganizationNPI = (identifiers: any[] | undefined) => {
        if (!identifiers) return ''

        const npi = identifiers.find(identifier => identifier.system === 'http://hl7.org/fhir/sid/us-npi')
        return npi?.value || ''
    }

    return (
        <section>
            <h3>Organizations</h3>
            <dl className='dataContainer'>
                <Row>
                    <Col sm={3}>
                        <dt>Name</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{organization?.name}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>NPI</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{getOrganizationNPI(organization?.identifiers)}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Address</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{organization?.address}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Networks</dt>
                    </Col>
                    <Col sm={9}>
                        {
                            organization?.networks?.map((network, index) => (
                                <dd key={index}>
                                    {network}
                                </dd>
                            ))
                        }
                    </Col>
                    <Col sm={3}>
                        <dt>Locations</dt>
                    </Col>
                    <Col sm={9}>
                        {
                            organization?.locations?.map((location: any) => (
                                <dd key={location.id}>
                                    <span className='font-weight-bold'>{capitalizeFirstLetter(location.address.use)}: </span> <span>{buildLocationAddress(location.address)}</span>
                                </dd>
                            ))
                        }
                    </Col>
                </Row>
            </dl>
        </section>
    )
}

export default OrganizationDetail