import React, { FC, useEffect, useState } from 'react'
import { Form, Alert, Button, Spinner, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface Props {
  formHandler: any;
  alertState: { show: boolean; variant: string; message: string; };
  closeAlert: () => void;
  isLoading: boolean;
}

const EnterMfaForm: FC<Props> = ({ formHandler, alertState, closeAlert, isLoading }: Props) => {

  return (
    <>
      <h3 className='headerText'>Log In</h3>
      <Form noValidate onSubmit={formHandler.handleSubmit} id='login'>
      {
        alertState.show && (
          <Alert variant={alertState.variant} dismissible onClose={closeAlert}>
            {alertState.message}
          </Alert>
        )
      }
        <Form.Group className='custom'>
          <Form.Label>
            Validation Code:
          </Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Validation Code'
            name='mfaCode'
            value={formHandler.values.mfaCode}
            onChange={formHandler.handleChange}
            onBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.mfaCode && formHandler.touched.mfaCode}
            id='mfaCode'
          />
          {
            !!formHandler.errors.mfaCode && formHandler.touched.mfaCode && (
              <Form.Text className='text-danger'>
                {formHandler.errors.mfaCode}
              </Form.Text>
            )
          }
        </Form.Group>
        <div>
          {!isLoading ? (
            <div>
              <Row>
                <Col>
                  <Button type='submit' className='custom'>Validate</Button>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <p className='small m-0'>
                  Missing or expired code? <Link to='/'>Start Over</Link>
                  </p>
                  <p className='small m-0'>Need Assistance? Contact Member Services under &quot;Help&quot;.</p>
                </Col>
              </Row>
            </div>
          ) : (
            <Button type='submit' className='custom' disabled={isLoading}>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                size='sm'
              />
            </Button>
          )}
        </div>
      </Form>
    </>
  )
}
 
export default EnterMfaForm