import React, { useEffect, useState } from 'react'
import { ConditionEntry, EncounterEntry, LocationEntry, PractitionerEntry } from '../../../../../types/FHIRTypes/Entry'
import { Encounter } from '../../../../../types/FHIRTypes/Encounter'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept, formatDateInTimeZone, formatDateWithFormat, handleReference, capitalizeFirstLetter, handleExtension } from '../../../../../utils/helpers'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Narrative from '../../../DisplayComponents/Narrative'
import { getDataAbsentReason } from '../../../../../utils/fhirUtils/getDataAbsentReason'
import { isCodeableConcept } from '../../../../../typeGuards/fhirTypes'
import { Extension } from '../../../../../types/FHIRTypes/Extension'
import { CodeableConcept } from '../../../../../types/FHIRTypes/CodeableConcept'
import { handleCodingOrExtension } from '../../../../../utils/fhirUtils/handleCodingOrExtension'
import { handleCodeableConceptOrExtension } from '../../../../../utils/fhirUtils/handleCodeableConceptOrExtension'


interface EncounterDetailProps {
    encounters: EncounterEntry[];
    encounterDetailId: string | undefined;
    conditions: ConditionEntry[];
    practitioners: PractitionerEntry[];
    locations: LocationEntry[];
    isFetching: boolean;
    dataExists: boolean;
    patientData: any;
}
const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    class: { label: 'Classification', visibility: 'always' },
    type: { label: 'Encounter Type', visibility: 'always' },
    participant: { label: 'Partipant', visibility: 'always' },
    period: { label: 'Period', visibility: 'always' },
    reasonCode: { label: 'Reason Code', visibility: 'always' },
    reasonReference: { label: 'Reason Reference', visibility: 'conditional' },
    diagnosis: { label: 'Diagnosis', visibility: 'conditional' },
    hospitalization: { label: 'Hospitalization', visibility: 'conditional' },
    location: { label: 'Location', visibility: 'always' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}


const EncounterDetail: React.FC<EncounterDetailProps> = ({ encounters, encounterDetailId, conditions, practitioners, locations, isFetching, dataExists, patientData }) => {
    const [encounter, setEncounter] = useState<Encounter | null>(null)

    const history = useHistory()

    useEffect(() => {
        if (dataExists) {
            const foundEncounter = encounters.find((e) => e.resource.id === encounterDetailId)

            if (foundEncounter) {
                setEncounter(foundEncounter.resource)
            } else {
                history.push('/404')
            }
        }
    }, [dataExists])

    return (
        <>
            {isFetching && (
                <>
                    <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                    />
                    Loading Member Encounters
                </>
            )}
            <dl className='dataContainer'>
                {encounter ? (
                    <Card>
                        <Card.Body>
                            <Row>
                                <ShowField field='subject' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.subject.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{handleReference(encounter?.subject)}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='status' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.status.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter?.status || getDataAbsentReason(encounter, 'status')}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field="class" config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.class.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{handleCodingOrExtension(encounter.class)}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='type' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.type.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter?.type && encounter.type.length > 0 ? handleCodeableConceptOrExtension(encounter.type[0]) : ''}</dd>
                                    </Col>
                                </ShowField>

                                <ShowField field='participant' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.participant.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <>
                                            {encounter?.participant?.map((participant, index) => (
                                                <dd key={`encounter-participant-${index}`}>
                                                    {participant.type?.map((type) => handleCodeableConcept(type)).join(', ') || 'Type'},
                                                    {participant.period?.start ? `Start: ${formatDateInTimeZone(participant.period.start, 'MM/dd/yyyy', 'UTC')}` : ''}
                                                    {participant.period?.end ? `End: ${formatDateInTimeZone(participant.period.end, 'MM/dd/yyyy', 'UTC')}` : ''},
                                                    {handleReference(participant.individual)}
                                                </dd>
                                            ))}
                                        </>
                                    </Col>
                                </ShowField>
                                <ShowField field='period' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.period.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter?.period ? `${encounter.period.start ? `Start: ${formatDateInTimeZone(encounter.period.start, 'MM/dd/yyyy', 'UTC')}` : ''} ${encounter.period.end ? `End: ${formatDateInTimeZone(encounter.period.end, 'MM/dd/yyyy', 'UTC')}` : ''}` : ''}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='reasonCode' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.reasonCode.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <>
                                            {
                                                encounter.reasonCode ? handleCodeableConceptOrExtension(encounter.reasonCode) : ''
                                            }
                                        </>
                                    </Col>
                                </ShowField>
                                <ShowField field='reasonReference' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.reasonReference.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {
                                            encounter?.reasonReference?.map((reason, index) => (
                                                <dd key={`encounter-reasonReference-${index}`}>{reason.reference}</dd>
                                            ))
                                        }
                                    </Col>
                                </ShowField>
                                <ShowField field='diagnosis' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.diagnosis.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {encounter?.diagnosis && encounter.diagnosis.map((diagnosis, index) => (
                                            <dd key={`diagnosis-reference-${index}`}>
                                                {diagnosis.condition?.reference}
                                            </dd>
                                        ))}
                                    </Col>
                                </ShowField>
                                <ShowField field='hospitalization' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.hospitalization.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {
                                            encounter?.hospitalization?.dischargeDisposition ? handleCodeableConcept(encounter.hospitalization.dischargeDisposition) : ''
                                        }
                                    </Col>
                                </ShowField>
                                <ShowField field='location' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.location.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        {encounter?.location?.map((loc, index) => (
                                            <dd key={`encounter-location-${index}`}>
                                                {handleReference(loc.location)}
                                            </dd>
                                        ))}
                                    </Col>
                                </ShowField>
                                <ShowField field='text' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.text.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {encounter?.text && <Narrative text={encounter.text} />}
                                        </dd>
                                    </Col>
                                </ShowField>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='footer'>
                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{capitalizeFirstLetter(encounter.resourceType)}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter.id}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>
                                            {encounter.meta?.lastUpdated
                                                ? formatDateWithFormat(
                                                    encounter.meta.lastUpdated,
                                                    'MM/dd/yyyy HH:mm:ss'
                                                )
                                                : ''}
                                        </dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter.meta?.profile?.join(', ')}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter.implicitRules}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter.language}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='identifier' config={config} resource={encounter}>
                                    <Col sm={3}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{encounter.identifier?.map((identifier: any) => identifier.value).join(', ')}</dd>
                                    </Col>
                                </ShowField>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ProvenanceDetail resourceName='Encounter' resourceId={encounterDetailId} />
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                ) : (
                    <div className='text-center'>
                        {'No Encounters found for this member.'}
                    </div>
                )}
            </dl >
        </>
    )
}

export default EncounterDetail