import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildPatientBirthday, buildPatientMemberId } from '../../../../utils/fhirUtils/patient'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import useEnvironment from '../../../../hooks/location/useEnviroment'
import { handleCodeableConcept, findExtensionByURI } from '../../../../utils/helpers'
import { Patient } from '../../../../types/FHIRTypes/Patient'
import HumanName from '../../DisplayComponents/HumanName'
import Address from '../../DisplayComponents/Address'
import Telecom from '../../DisplayComponents/Telecom'
import MetaDataFooter from '../MetaDataFooter'
import ProvenanceDetail from '../Provenance'
import PatientContact from '../../DisplayComponents/PatientContact'
import Narrative from '../../DisplayComponents/Narrative'
import Identifier from '../../DisplayComponents/Identifier'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface Props {
  patientData: Patient | undefined;
  loading: boolean;
  coverageData: any
}

const MemberProfile: FC<Props> = ({ patientData, loading, coverageData }: Props) => {
  const { data: environmentData } = useEnvironment()

  const patientRace = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race')
  const patientEthnicity = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity')
  const patientBirthSex = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex')

  return (
    <div>
      <h3>Member Profile</h3>
      <h4>
        {patientData?.name ? <HumanName name={patientData.name[0]} /> : ''}
      </h4>
      <section>
        <dl className={`dataContainer ${loading ? 'd-flex' : ''}`}>
          {
            loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                />
                <span style={{ marginLeft: '10px' }}>Loading Member Profile</span>
              </>
            ) : (
              <Row>
                <Col sm={3}>
                  <dt>Most Recent Member ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      getContentOrFallback(buildPatientMemberId(patientData, coverageData, environmentData?.isSingleTenantServer))
                    }
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Patient Identifier</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    <Identifier identifiers={patientData?.identifier} />
                  </dd>
                </Col>
                {
                  patientData?.active !== undefined && (
                    <>
                      <Col sm={3}>
                        <dt>Resource Status</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {getContentOrFallback(patientData.active ? 'Active' : 'Inactive')}
                        </dd>
                      </Col>
                    </>
                  )
                }
                <Col sm={3}>
                  <dt>Name</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      patientData?.name.map((name, index) => <HumanName key={`name-${index}`} name={name} showUse={patientData.name.length > 1} />)
                    }
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Gender</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {getContentOrFallback(capitalizeFirstLetter(patientData?.gender))}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Birth Date</dt>
                </Col>
                <Col sm={9}>
                  <dd>{getContentOrFallback(buildPatientBirthday(patientData))}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Address</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      patientData?.address?.map((address, index) => (
                        <Address
                          key={`address-${index}`}
                          address={address}
                          showUse={patientData.address && patientData.address.length > 1}
                        />
                      ))
                    }
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Communication</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      getContentOrFallback(patientData?.communication?.map((communication) => handleCodeableConcept(communication.language)).join(', '))
                    }
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Contact</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {patientData?.telecom?.map((telecom, index) => <Telecom key={`telecom-${index}`} contactPoint={telecom} />)}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Race</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {getContentOrFallback(patientRace?.extension?.[1].valueString)}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Ethnicity</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {getContentOrFallback(patientEthnicity?.extension?.[1].valueString)}
                  </dd>
                </Col>
                <Col sm={3}>
                  <dt>Birth Sex</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {getContentOrFallback(patientBirthSex?.extension?.[1].valueString)}
                  </dd>
                </Col>
                {
                  patientData?.maritalStatus && (
                    <>
                      <Col sm={3}>
                        <dt>Marital Status</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {getContentOrFallback(capitalizeFirstLetter(handleCodeableConcept(patientData.maritalStatus)))}
                        </dd>
                      </Col>
                    </>
                  )
                }
                {
                  (patientData?.multipleBirthBoolean !== undefined || patientData?.multipleBirthInteger !== undefined) && (
                    <>
                      <Col sm={3}>
                        <dt>Multiple Birth</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {patientData.multipleBirthBoolean !== undefined
                            ? patientData.multipleBirthBoolean ? 'Yes' : 'No'
                            : patientData.multipleBirthInteger}
                        </dd>
                      </Col>
                    </>
                  )
                }

                {
                  patientData?.generalPractitioner && (
                    <>
                      <Col sm={3}>
                        <dt>General Practitioner</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {patientData.generalPractitioner.map((gp, index) => <div key={`gp-${index}`}>{getContentOrFallback(gp.reference)}</div>)}
                        </dd>
                      </Col>
                    </>
                  )
                }
                {
                  patientData?.managingOrganization && (
                    <>
                      <Col sm={3}>
                        <dt>Managing Organization</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {getContentOrFallback(patientData.managingOrganization.reference)}
                        </dd>
                      </Col>
                    </>
                  )
                }
                {
                  patientData?.contact && (
                    <>
                      <Col sm={3}>
                        <dt>Patient Contacts</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {patientData.contact.map((contact, index) => (
                            <PatientContact key={`contact-${index}`} contact={contact} />
                          ))}
                        </dd>
                      </Col>
                    </>
                  )
                }
                {
                  (patientData?.deceasedBoolean !== undefined || patientData?.deceasedDateTime !== undefined) && (
                    <>
                      <Col sm={3}>
                        <dt>Deceased</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          {patientData.deceasedBoolean !== undefined
                            ? patientData.deceasedBoolean ? 'Yes' : 'No'
                            : patientData.deceasedDateTime}
                        </dd>
                      </Col>
                    </>
                  )
                }
                {
                  patientData?.text && (
                    <>
                      <Col sm={3}>
                        <dt>Text</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>
                          <Narrative text={patientData.text} />
                        </dd>
                      </Col>
                    </>
                  )
                }
                <Col sm={12}>
                  <MetaDataFooter
                    resourceType={patientData?.resourceType}
                    resourceId={patientData?.id}
                    resourceMeta={patientData?.meta}
                    resourceLanguage={patientData?.language}
                    resourceImplicitRules={patientData?.implicitRules}
                  />
                </Col>

                <Col sm={12}>
                  <ProvenanceDetail resourceName='Patient' resourceId={patientData?.id} />
                </Col>
              </Row>
            )
          }
        </dl>
      </section>

    </div>
  )
}

export default MemberProfile