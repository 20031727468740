import request from 'superagent'
import { useMutation } from 'react-query'
import { getApiServer } from '../../utils/domain'
import { CustomHttpError } from '../../types/CustomHttpError'

export default function useUpdateDeveloperApplication() {
  return useMutation(
    (values: any) => request.put(`${getApiServer()}/developer/app/${values.appId}`).set('Accept', 'application/json').send(values).then((res) => res.body), {
    onError: (error: CustomHttpError) => {
      return error.response.text
    }
  })
}