import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

interface Payload {
  searchTerm?: string;
  page?: number;
  category?: string;
  pagesOffset?: string,
  pageState?: string,
  pageId?: string
}

export default function useMembers(payload: Payload): any {
  return useQuery(['members'], async () => {
    const query = {
      page: payload.page,
      category: payload.category,
      searchTerm: payload.searchTerm || undefined,
      pagesOffset: payload.pagesOffset || undefined,
      pageState: payload.pageState || undefined,
      pageId: payload.pageId || undefined,
    }

    return await request.get(`${getApiServer()}/members`).query(query).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 5 * 60 * 1000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}