import React, { FC } from 'react'
import { Address as FhirAddress } from '../../../../types/FHIRTypes/Address'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface AddressProps {
    address: FhirAddress;
    showUse?: boolean;
}

const Address: FC<AddressProps> = ({ address, showUse }) => {
    const getAddress = () => {
        const fullAddress = address.text || `${address.line?.join(' ') || ''} ${address.city || ''}, ${address.state || ''} ${address.postalCode || ''}`.trim()
        const useType = showUse && address.use ? `Type: ${address.use}` : ''
        return `${fullAddress} ${useType}`
    }

    return (
        <span>
            {getContentOrFallback(getAddress())}
        </span>
    )
}

export default Address