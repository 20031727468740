import Lottie from 'lottie-react'
import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import loader from '../../assets/customizations/logos/loader.json'

interface Props {
    message: string
}

const Loader: FC<Props> = ({ message }: Props) => {
    return (
        <Container style={{ height: '100vh' }} className='d-flex flex-column align-items-center text-center'>
            <Lottie loop animationData={loader} style={{ height: 450, width: 450 }} />
            <div>
                {message}
            </div>
        </Container>
    )
}

export default Loader