import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Form, Modal, Spinner } from 'react-bootstrap'
import { useFormik } from 'formik'
import { ForgotPasswordSchema, ForgotUsernameSchema } from '../../../utils/validation'
import { addCompanyPhoneMessage } from '../../../utils/helpers'
import ReCAPTCHA from 'react-google-recaptcha'
import useEnvironment from '../../../hooks/location/useEnviroment'

interface Props {
  show: boolean;
  onHide: () => void;
  modalType: string;
  forgotLogin: any;
}

interface FormValues {
  memberId?: string;
  email?: string;
  username?: string;
  recaptcha: string | null;
}

const ForgotLoginModal: FC<Props> = ({ show, onHide, modalType, forgotLogin }: Props) => {
  const [alertState, setAlertState] = useState({
    show: false,
    variant: 'success',
    message: ''
  })

  const { data: environmentData, isSuccess: isEnvironmentSuccess } = useEnvironment()

  const formik = useFormik<FormValues>({
    initialValues: {
      ...(modalType === 'Username' ? { memberId: '', email: '' } : { username: '' }),
      recaptcha: null
    },
    enableReinitialize: true,
    validationSchema: modalType === 'Username' ? ForgotUsernameSchema : ForgotPasswordSchema,
    onSubmit: (values) => {
      forgotLogin.mutate({
        ...values,
        type: modalType
      })
    }
  })

  useEffect(() => {
    if (forgotLogin.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: modalType === 'Username' ? 'Please check your email for username' : 'Please check your email for next steps.'
      })
    }

    if (forgotLogin.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: addCompanyPhoneMessage(forgotLogin.error.response.text)
      })
    }
  }, [forgotLogin.isSuccess, forgotLogin.isError])

  const handleCloseModal = () => {
    setAlertState({
      show: false,
      variant: 'success',
      message: ''
    })

    onHide()
  }
  return (
    <Modal show={show} onHide={handleCloseModal} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title className='font-primary-color'>Forgot {modalType}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {
            alertState.show && (
              <Alert variant={alertState.variant} dismissible onClose={() => setAlertState((ps => ({ ...ps, show: false })))}>
                {alertState.message}
              </Alert>
            )
          }
          <Card.Text>Please enter your {modalType === 'Username' ? 'Member ID and the email address' : 'username'} associated with your account.</Card.Text>
          {
            modalType === 'Username' && (
              <>
                <Form.Group>
                  <Form.Label>Member ID</Form.Label>
                  <Form.Control
                    type='text'
                    name='memberId'
                    value={formik.values.memberId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.memberId && !!formik.touched.memberId}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.memberId}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='text'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.email && !!formik.touched.email}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )
          }
          {
            modalType === 'Password' && (
              <>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type='text'
                    name='username'
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.username && !!formik.touched.username}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )
          }
          <div className='my-3'>
            {
              isEnvironmentSuccess && (
                <ReCAPTCHA
                  sitekey={environmentData?.googleRecaptchaSiteKey || ''}
                  onChange={(value) => formik.setFieldValue('recaptcha', value)}
                />
              )
            }
            {
              Boolean(!!formik.errors.recaptcha && formik.touched.recaptcha) && (
                <Form.Text className='text-danger'>
                  {formik.errors.recaptcha}
                </Form.Text>
              )
            }
          </div>
          <div className='float-right mt-3'>
            <Button className='mr-2 modalBtn' variant='light' onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              className='modalBtn custom'
              type='submit'
            >
              {
                forgotLogin.isLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    role='status'
                    size='sm'
                    aria-hidden='true'
                  />
                ) : 'Submit'
              }
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default ForgotLoginModal
