import { useEffect, useState } from 'react'
import useUser from './useUser'

export const useRole = (): any => {
    const { data: user } = useUser()

    const [role, setRole] = useState('')

    useEffect(() => {
        let userRole = ''
        if (user && !userRole) {
            userRole = user.role
        }
        if (userRole) {
            setRole(userRole)
        }
    }, [user])

    return { role, setRole }
}