import { sub } from 'date-fns'
import * as Yup from 'yup'
import { testStringWithRegex, isValidUrl } from './helpers'

const numbersOnlyRegex = /^\d+$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/
const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const recaptchaValidation = Yup.string().nullable().required('Please complete the reCAPTCHA challenge.')


export const MemberSchema = Yup.object().shape({
  memberId: Yup.string().required('This field is required.'),
  memberLastName: Yup.string().required('This field is required.'),
  useDOBFlag: Yup.boolean(),
  dob: Yup.date().when('useDOBFlag', {
    is: true,
    then: Yup.date().typeError('This field is required.').required('This field is required.')
  }),
  useSSNFlag: Yup.boolean(),
  ssn: Yup.string().when('useSSNFlag', {
    is: true,
    then: Yup.string().required('This field is required.')
  })
})

// export const RequestorSchema = Yup.object().shape({
//   firstName: Yup.string().required('This field is required.'),
//   lastName: Yup.string().required('This field is required.'),
//   type: Yup.string().required('Please select whether you are the Member or the Parent/Legal Guardian.'),
// })


export const RequestorSchema = Yup.object().shape({
  type: Yup.string().required('Please select whether you are registering for yourself or are the legal guardian/poa.'),
  firstName: Yup.string().when('type', {
    is: 'proxy',
    then: Yup.string().required('This field is required.')
  }),
  lastName: Yup.string().when('type', {
    is: 'proxy',
    then: Yup.string().required('This field is required.')
  }),
  acceptedTerms: Yup.bool().oneOf([true], 'Please agree to the Terms and Conditions')
})

export const ContactInfoSchema = Yup.object().shape({
  email: Yup.string().email('Please specify a valid email.').required('This field is required.'),
  recaptcha: recaptchaValidation
})

export const CreateAccountSchema = Yup.object().shape({
  username: Yup.string().required('This field is required.'),
  password: Yup.string()
    .min(16, 'Password must be a minimum of 16 characters')
    .test(
      'AtLeast3Required',
      'Please include at least 1 Uppercase letter, 1 Lowercase letter, 1 Number, or 1 Special Character',
      (value) => {
        const hasUpperCase = testStringWithRegex(value, /[A-Z]/)

        const hasLowerCase = testStringWithRegex(value, /[a-z]/)

        const hasNumber = testStringWithRegex(value, /[0-9]/)

        const hasSymbol = testStringWithRegex(value, /[^\w]/)

        const total = (hasUpperCase ? 1 : 0) + (hasLowerCase ? 1 : 0) + (hasNumber ? 1 : 0) + (hasSymbol ? 1 : 0)
        return total >= 3
      }
    )
    .required('Password is required'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match.').required('This field is required.'),
})

export const LoginSchema = Yup.object().shape({
  username: Yup.string().required('This field is required.'),
  password: Yup.string().required('This field is required.')
})

export const ForgotUsernameSchema = Yup.object().shape({
  memberId: Yup.string().required('This field is required.'),
  email: Yup.string().email('Please specify a valid email.').required('This field is required.'),
  recaptcha: recaptchaValidation
})

export const ForgotPasswordSchema = Yup.object().shape({
  username: Yup.string().required('This field is required.'),
  recaptcha: recaptchaValidation
})

const changePasswordBaseSchema = {
  password: Yup.string()
    .min(16, 'Password must be a minimum of 16 characters')
    .test(
      'AtLeast3Required',
      'Please include at least 1 Uppercase letter, 1 Lowercase letter, 1 Number, or 1 Special Character',
      (value) => {
        const hasUpperCase = testStringWithRegex(value, /[A-Z]/)

        const hasLowerCase = testStringWithRegex(value, /[a-z]/)

        const hasNumber = testStringWithRegex(value, /[0-9]/)

        const hasSymbol = testStringWithRegex(value, /[^\w]/)

        const total = (hasUpperCase ? 1 : 0) + (hasLowerCase ? 1 : 0) + (hasNumber ? 1 : 0) + (hasSymbol ? 1 : 0)
        return total >= 3
      }
    )
    .required('Password is required'),
  passwordAgain: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match.').required('This field is required.')
}

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('This field is required.'),
  ...changePasswordBaseSchema
})

export const ResetPasswordSchema = Yup.object().shape({
  ...changePasswordBaseSchema
})

export const AppRegisterSchema = Yup.object().shape({
  readTerms: Yup.boolean().oneOf([true], 'Please review and agree to the Terms and Conditions'),
  readFhirApiDocumentation: Yup.boolean().oneOf([true], 'Please review and agree to the Fhir Documentation'),
  readSmartOnFhirDocumentation: Yup.boolean().oneOf([true], 'Please review and agree to the Smart on Fhir Documentation'),
  readCarin: Yup.boolean().oneOf([true], 'Please review and agree to the Carin Code of Conduct.'),
  companyName: Yup.string().required('This field is required.'),
  address1: Yup.string().required('This field is required.'),
  address2: Yup.string().nullable(),
  city: Yup.string().required('This field is required.'),
  state: Yup.string().required('This field is required.'),
  zip: Yup.string().matches(zipRegex, 'Please provide a valid zip/postal code').required('This field is required.'),
  url: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  appName: Yup.string().required('This field is required.'),
  appDescription: Yup.string().max(255, 'Character length is 255').required('This field is required.'),
  termsUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  redirectUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  iosAppId: Yup.string().required('This field is required.'),
  iosAppUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  androidAppId: Yup.string().required('This field is required.'),
  androidAppUrl: Yup.string().test('is-url-valid', 'Please provide a valid url.', (value) => isValidUrl(value || '')).required('This field is required.'),
  contactName: Yup.string().required('This field is required.'),
  email: Yup.string().email('Please provide a valid email.').required('This field is required.'),
  phone: Yup.string().matches(phoneRegex, 'Please provide a valid phone number.').required('This field is required.')
})

export const ContactSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  email: Yup.string().email('Please specify a valid email.').required('This field is required.'),
  memberId: Yup.string().required('This field is required.'),
  message: Yup.string().required('This field is required.')
})

export const memberIdSchema = Yup.object().shape({
  memberId: Yup.string().required('Please Enter a Member ID'),
})