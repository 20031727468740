import React, { FC, useEffect, useState } from 'react'
import { Alert, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Member from '../../components/MemberRegistration/Member'
import Requestor from '../../components/MemberRegistration/Requestor'
import ContactInfo from '../../components/MemberRegistration/ContactInfo'
import ValidateEmail from '../../components/MemberRegistration/ValidateEmail'
import CreateAccount from '../../components/MemberRegistration/CreateAccount'
import useValidateMember from '../../hooks/authentication/useValidateMember'
import useSendCode from '../../hooks/authentication/useSendCode'
import useCreateMember from '../../hooks/authentication/useCreateMember'
import useValidateEmail from '../../hooks/authentication/useValidateEmail'
import { appConfig } from '../../assets/customizations/config'
import '../../App.scss'

import { FormattedMessage, IntlProvider } from 'react-intl'

import {
  companyMessage,
  companyNameFull,
  preferedName,
  dataPortalTitle,
  companyDataDescription,
  contactLink,
  contactEmail,
  contactPhone,
  contactHours
} from '../../assets/i18n/i18n'
import { addCompanyPhoneMessage } from '../../utils/helpers'

interface MatchParams {
  step: string;
}

const steps = ['requestor', 'member', 'contact', 'validate', 'create']

const initialMemberState = {
  memberId: '',
  memberLastName: '',
  useDOBFlag: appConfig.use_dob_flag,
  dob: null,
  useSSNFlag: appConfig.use_ssn_flag,
  ssn: '',
  pid: ''
}

const initialRequestorState = {
  type: 'self',
  firstName: '',
  lastName: '',
  acceptedTerms: false,
  pid: ''
}

const initialContactInfoState = {
  email: ''
}

const initalCreateAccountState = {
  username: '',
  password: '',
  passwordAgain: ''
}

const Register: FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [memberState, setMemberState] = useState(initialMemberState)

  const [requestorState, setRequestorState] = useState(initialRequestorState)
  const [contactInfoState, setContactInfoState] = useState(initialContactInfoState)
  const [createAccountState, setCreateAccountState] = useState(initalCreateAccountState)

  const match = useRouteMatch<MatchParams>()
  const history = useHistory()
  const { step } = match.params

  const goToNextStep = () => {
    setActiveStep(activeStep + 1)
    history.push(`/register/${steps[activeStep + 1]}`)
  }

  const validatedMember = useValidateMember()
  useEffect(() => {
    if (validatedMember.isSuccess && activeStep === 1) {
      if (validatedMember.data) {
        if (validatedMember.data.verified) {
          goToNextStep()
        }
      }
    }
  }, [validatedMember.isSuccess, activeStep])

  const sendMemberCode = useSendCode()
  useEffect(() => {
    if (sendMemberCode.isSuccess && activeStep === 2) {
      if (sendMemberCode.data === 'ok') {
        goToNextStep()
      }
    }
  }, [sendMemberCode.isSuccess, activeStep])

  const handleGoBackToContactStep = () => {
    history.push('/register/contact')
    sendMemberCode.reset()
  }

  const validateEmail = useValidateEmail()
  useEffect(() => {
    if (validateEmail.isSuccess && activeStep === 3) {
      goToNextStep()
    }
  }, [validateEmail.isSuccess, activeStep])

  const handleSendNewCode = () => {
    sendMemberCode.mutate({
      ...contactInfoState,
      ...validatedMember.data[0],
      type: requestorState.type
    })
  }

  const createMember = useCreateMember()

  useEffect(() => {
    if (!step) {
      history.push('/register/requestor')
    } else {
      setActiveStep(steps.indexOf(step))
    }
  }, [step])

  useEffect(() => {
    if (step === 'requestor') {
      setMemberState(initialMemberState)
    }
  }, [step])

  const handleSubmit = (values: any) => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
          setRequestorState(values)
          goToNextStep()
          break
        case 1:
          validatedMember.mutate(values)
          setMemberState({
            memberId: values.memberId,
            memberLastName: values.lastname,
            useDOBFlag: appConfig.use_dob_flag,
            dob: values.dob,
            useSSNFlag: appConfig.use_ssn_flag,
            ssn: values.ssn,
            pid: ''
          })
          break
        case 2:
          setContactInfoState(values)
          sendMemberCode.mutate({
            ...values,
            firstName: requestorState.firstName,
            lastName: requestorState.lastName,
            memberId: memberState.memberId,
            type: requestorState.type,
            // pid: validatedMember.data[0].pid
          })
          break
        case 3:
          validateEmail.mutate(values)
          break
        default:
          break
      }
    } else {
      setCreateAccountState(values)
      createMember.mutate({
        ...values,
        member: validateEmail.data?.member
      })
      // TODO: generate secret here for new user

    }
  }



  const renderRegistrationStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Requestor
            initialValues={requestorState}
            onSubmit={handleSubmit}
          />
        )
      case 1:
        return (
          <Member
            initialValues={memberState}
            memberType={requestorState.type}
            isLoading={validatedMember.isLoading}
            validateResults={validatedMember.data}
            isError={validatedMember.isError}
            error={addCompanyPhoneMessage(validatedMember.error?.response.text)}
            validatedMember={validatedMember}
            onSubmit={handleSubmit}
          />
        )
      case 2:
        return (
          <ContactInfo
            initialValues={contactInfoState}
            isLoading={sendMemberCode.isLoading}
            isError={sendMemberCode.isError}
            error={sendMemberCode.error?.response.text}
            onSubmit={handleSubmit}
          />
        )
      case 3:
        return (
          <ValidateEmail
            memberDetails={contactInfoState}
            validateResults={validateEmail.data}
            isLoading={validateEmail.isLoading}
            isError={validateEmail.isError}
            error={validateEmail.error?.response.text}
            sendNewCode={handleSendNewCode}
            sendNewCodeResult={sendMemberCode}
            goBack={handleGoBackToContactStep}
            onSubmit={handleSubmit}
          />
        )
      case 4:
        return (
          <CreateAccount
            initialValues={createAccountState}
            isLoading={createMember.isLoading}
            isError={createMember.isError}
            error={createMember.error?.response.text}
            onSubmit={handleSubmit}
          />
        )
      default:
        return 'bad step passed through'
    }
  }

  return (
    <main className='form'>

      <IntlProvider locale='en' messages={companyMessage}>

        <Container>
          {
            createMember.isSuccess ? (
              <div>
                <div className='bannerWithHelp'>
                  <h2>Create Account</h2>
                  <h3><Link to='/help'>Need Help?</Link></h3>
                </div>
                <Alert variant='success'>
                  Success! Your account has been created. You can now <Alert.Link href='/login' className='text-decoration-underline'>Log In</Alert.Link>
                </Alert>
              </div>
            ) : (
              <div>
                <div className='bannerWithHelp'>
                  <h2 className='headerText'>
                    {
                      activeStep < 4 ? 'Register' : 'Create Account'
                    }
                  </h2>
                  {
                    activeStep < 4 && (
                      <p className='steps'>Step {activeStep + 1} of 4</p>
                    )
                  }
                  <Link to='/help' className='helpLink'><h3>Need Help?</h3></Link>
                </div>
                {
                  activeStep === 0 && (
                    <p>
                      <strong>Hello! </strong>You are registering for the <strong>{preferedName} {dataPortalTitle}</strong>, which allows you to view your {companyDataDescription} and connect your health information to apps in the marketplace.
                    </p>
                  )
                }
                {
                  renderRegistrationStep(activeStep)
                }
              </div>
            )
          }
        </Container>
      </IntlProvider>
    </main>
  )
}

export default Register
