import React, { FC } from 'react'
import CareTeamList from './CareTeamList'
import CareTeamDetail from './CareTeamDetail'
import { CareTeamBundle } from '../../../../types/FHIRTypes/Bundle'
import { buildPatientName } from '../../../../utils/fhirUtils/patient'

interface Props {
  patientData: any;
  careTeamData: CareTeamBundle;
  careTeamDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const CareTeams: FC<Props> = ({ patientData, careTeamData, careTeamDetailId, page, handleChangePage, isFetching }) => {
  return (
    <div>
      <h3>Care Teams</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
      {
        careTeamDetailId && page === 'CareTeam'
          ? (
            <CareTeamDetail
              careTeamDetailId={careTeamDetailId}
              careTeamData={careTeamData}
              isFetching={isFetching}
            />
          )
          : (
            <CareTeamList
              careTeamData={careTeamData}
              handleChangePage={handleChangePage}
              isFetching={isFetching}
            />
          )
      }
    </div>
  )
}

export default CareTeams