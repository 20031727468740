import { useMutation } from 'react-query'
import { CustomHttpError } from '../../types/CustomHttpError'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useValidateForgotPasswordCode() {
    return useMutation(
        (values: any) => request.post(`${getApiServer()}/auth/validate-password-code`).send(values).then((res) => res.body), {
        onError: (error: CustomHttpError) => {
            return error.response.text
        }
    }
    )
}