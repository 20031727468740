import request from 'superagent'
import { UseMutationResult, useMutation } from 'react-query'
import { getApiServer } from '../../utils/domain'
import { CustomHttpError } from '../../types/CustomHttpError'

export default function useChangePassword(): UseMutationResult<any, CustomHttpError, any, any> {
  return useMutation(
    (values: any) => request.post(`${getApiServer()}/auth/change-password`).set('Accept', 'application/json').withCredentials().send(values).then((res) => res.body), {
    onError: (error: CustomHttpError) => {
      return error.response.text
    }
  })
}