import React, { useEffect } from 'react'
import { ProcedureBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { ProcedureEntry } from '../../../../../types/FHIRTypes/Entry'
import { capitalizeFirstLetter, handleCodeableConcept } from '../../../../../utils/helpers'
import { Procedure } from '../../../../../types/FHIRTypes/Procedure'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'



interface ProcedureDetailProps {
    procedureDetailId: string | undefined;
    procedureData: ProcedureBundle;
    isFetching: boolean;
}

const ProcedureDetail: React.FC<ProcedureDetailProps> = ({ procedureData, procedureDetailId, isFetching }) => {
   
    const [procedure, setProcedure] = React.useState<ProcedureEntry | undefined>(undefined)
    const history = useHistory()


    useEffect(() => {
        if (!procedureData) return

        const foundProcedure = procedureData?.entry?.find((procedure) => procedure.resource.id === procedureDetailId)
        if (foundProcedure) {
            setProcedure(foundProcedure)
        } else {
            history.push('/404')
        }
    }, [procedureData])

    const getProcedurePerformedDate = (procedure: Procedure) => {
        if (procedure.performedDateTime) {
            return new Date(procedure.performedDateTime).toLocaleDateString()
        }

        if (procedure.performedPeriod) {
            const startDate = procedure.performedPeriod.start ? new Date(procedure.performedPeriod.start).toLocaleDateString() : ''
            const endDate = procedure.performedPeriod.end ? new Date(procedure.performedPeriod.end).toLocaleDateString() : ''
            return endDate ? `${startDate} - ${endDate}` : startDate
        }

        if (procedure.performedString) {
            return procedure.performedString
        }

        if (procedure.performedAge) {
            return `${procedure.performedAge}`
        }

        if (procedure.performedRange) {
            return `${procedure.performedRange.low.value} - ${procedure.performedRange.high.value}`
        }

        return ''
    }

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                />
                Loading Procedure...
            </div>
        )
    }

    return (
        <>
            <dl className='dataContainer'>
                <Row>

                    <Col sm={3}>
                        <dt>Status</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{capitalizeFirstLetter(procedure?.resource.status)}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Code</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.code ? handleCodeableConcept(procedure?.resource.code) : ''}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Performed</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure ? getProcedurePerformedDate(procedure.resource) : ''}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Id</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.id}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Based On</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.basedOn?.map((based) => based.display).join(', ')}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Part Of</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.partOf?.map((part) => part.display).join(', ')}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Status Reason</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.statusReason ? handleCodeableConcept(procedure?.resource.statusReason) : ''}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Category</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.category ? handleCodeableConcept(procedure?.resource.category) : ''}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Subject</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.subject.display}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Encounter</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.encounter.display}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Recorder</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.recorder?.display}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Asserter</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.asserter?.display}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Performer</dt>
                    </Col>
                    <Col sm={9}>
                        {
                            procedure?.resource.performer?.map((performer, index) => (
                                <div key={`performer-${index}`} className='border rounded p-3 mb-3'>
                                    <Row>
                                        <Col xs={6}>
                                            Function
                                        </Col>
                                        <Col xs={6}>
                                            {performer.function ? handleCodeableConcept(performer.function) : ''}
                                        </Col>
                                        <Col xs={6}>
                                            Actor
                                        </Col>
                                        <Col xs={6}>
                                            {performer.actor?.display}
                                        </Col>
                                        <Col xs={6}>
                                            On Behalf Of
                                        </Col>
                                        <Col xs={6}>
                                            {performer.onBehalfOf?.display}
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }
                    </Col>
                    <Col sm={3}>
                        <dt>Location</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{procedure?.resource.location?.display}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Reason Code</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.reasonCode?.map((reason) => handleCodeableConcept(reason)).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Reason Reference</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.reasonReference?.map((reason) => reason.display).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Body Site</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.bodySite?.map((site) => handleCodeableConcept(site)).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Outcome</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {procedure?.resource.outcome ? handleCodeableConcept(procedure?.resource.outcome) : ''}
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Report</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.report?.map((report) => report.display).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Complication</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.complication?.map((complication) => handleCodeableConcept(complication)).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Complication Details</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.complicationDetail?.map((complicationDetail) => complicationDetail.display).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Follow Up</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.followUp?.map((followUp) => handleCodeableConcept(followUp)).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Notes</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.note?.map((note, index) => (
                                    <Card key={`note-${index}`}>
                                        <Card.Header>
                                            {note.authorReference?.display || note.authorString} - {note.time ? new Date(note.time).toLocaleDateString() : ''}
                                        </Card.Header>
                                        <Card.Body>
                                            {note.text}
                                        </Card.Body>
                                    </Card>
                                ))
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Focal Device</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.focalDevice?.map((focalDevice, index) => (
                                    <div key={`performer-${index}`} className='border rounded p-3 mb-3'>
                                        <Row>
                                            <Col xs={6}>
                                                Action
                                            </Col>
                                            <Col xs={6}>
                                                {focalDevice.action ? handleCodeableConcept(focalDevice.action) : ''}
                                            </Col>
                                            <Col xs={6}>
                                                Manipulated
                                            </Col>
                                            <Col xs={6}>
                                                {focalDevice.manipulated?.display}
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Items Used</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.usedReference?.map((used) => used.display).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Items Used Code(s)</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>
                            {
                                procedure?.resource.usedCode?.map((code) => handleCodeableConcept(code)).join(', ')
                            }
                        </dd>
                    </Col>
                    <Col sm={12}>
                        <ProvenanceDetail resourceName='Procedure' resourceId={procedureDetailId} />
                    </Col>

                </Row>
            </dl>
        </>
    )
}

export default ProcedureDetail