import { useState } from 'react'
import useFhirResource from './useFhirResource'
import { getUrlParameter } from '../../utils/helpers'

const usePaginatedFhirResource = (fhirId:any, resourceType:any, pageSize = 25) => {
  const [pageState, setPageState] = useState({
    getPages: '',
    getPagesOffset: '',
    pageId: ''
  })

  const {data, refetch, isStale, isFetching } = useFhirResource(
    fhirId, 
    resourceType, 
    pageSize, 
    pageState.getPages, 
    pageState.getPagesOffset, 
    pageState.pageId
  )

  const handleChangePage = (url:string) => {
    setPageState({
      getPages: getUrlParameter('_getpages', url),
      getPagesOffset: getUrlParameter('_getpagesoffset', url),
      pageId: getUrlParameter('_pageId', url)
    })
  }

  return {data, refetch, isStale, isFetching, handleChangePage }
}

export default usePaginatedFhirResource