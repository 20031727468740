import React, { FC, useEffect } from 'react'
import useApps from '../../hooks/app/useApps'
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, IntlProvider } from 'react-intl'
import '../../App.scss'

import {
    companyMessage,
    companyNameFull,
    preferedName,
    dataPortalTitle,
    contactLink,
    contactEmail,
    contactPhone,
    companyDataDescription
} from '../../assets/i18n/i18n'
import { appConfig as appConfigData } from '../../assets/customizations/config'

interface AvailableAppsConfig {
    available_apps_flexpa: boolean;
    [key: string]: boolean | string | Array<any>; // index signature for dynamic properties
}

const availableAppsConfig: AvailableAppsConfig = appConfigData

// add properties for each app
const appProperties = [
    {
        name: 'Flexpa',
        link: 'https://my.flexpa.com/',
        key: 'available_apps_flexpa'
    },
]

const formatLink = (cellContent: string) => <a href={cellContent} target='_blank' rel="noreferrer" >{cellContent}</a>

const AvailableApps: FC = () => {
    const { data: apps, refetch: refetchApps } = useApps('All')

    // find available_apps_ 
    const availableAppKeys = Object.keys(availableAppsConfig)
        .filter(key => key.startsWith('available_apps_') && availableAppsConfig[key] === true)

    // filter app properties based on available keys
    const availableAppProperties = appProperties.filter(app => availableAppKeys.includes(app.key))

    useEffect(() => {
        refetchApps()
    }, [])

    return (
        <main className='content'>
            <IntlProvider locale='en' messages={companyMessage}>
                <Container>
                    <h2 className='headerText'>Available Apps</h2>
                    <p>A Member&apos;s account with the {preferedName} {dataPortalTitle} can be used with the following apps at this time. {companyNameFull} will appear when searching in these apps.</p>

                    <p>All apps approved for use with the {preferedName} {dataPortalTitle} have attested to the <a href='https://www.carinalliance.com/code-of-conduct' target='blank' rel="noreferrer">Carin Code of Conduct</a>. It is important to know that once an external app is authorized, that app has its own level of security and may not be HIPAA-compliant. Review the terms of use of any external app prior to granting access. Only grant access to trusted apps. To reduce potential exposure, limit the number of external apps that are used. For additional information, please see the <a href='/help'>Help</a> page.</p>

                    <p>If a Member would like to use an external app not shown on the list, please <a href="/help#ContactUs">Contact Us</a>.</p>

                    {availableAppKeys.length === 0 ? (
                        <div className='calloutContainer text-center'>
                            <h4 className='font-weight-bold'>No apps currently available</h4>
                            <p className='mb-0'>To request a new app, please contact us using the information the <a href='/help'>Help</a> page.</p>
                        </div>
                    ) : (
                        availableAppProperties.map((app, index) => (
                            <div className='calloutContainer' key={index}>
                                <Row className='align-items-center'>
                                    <Col sm={4}>
                                        <h4 className='mb-0 font-weight-bold'>{app.name}</h4>
                                    </Col>
                                    <Col sm={4}>
                                        {formatLink(app.link)}
                                    </Col>
                                    <Col sm={4}>
                                        <p className='mb-0 text-success h4'>
                                            Available
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    )}
                </Container>
            </IntlProvider>
        </main>
    )
}

export default AvailableApps
