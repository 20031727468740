import { useState } from 'react'

interface AlertState {
  show: boolean
  variant: string
  message: string
}

const useAlert = (initialAlertState: AlertState): {
  alert: AlertState
  handleCloseAlert: () => void
  showAlert: (variant: string, message: string) => void
} => {
  const [alert, setAlert] = useState<AlertState>(initialAlertState)

  const handleCloseAlert = () => {
    setAlert((ps) => ({
      ...ps,
      show: false
    }))
  }

  const showAlert = (variant: string, message: string) => {
    setAlert({
      show: true,
      variant,
      message
    })
  }

  return { alert, handleCloseAlert, showAlert }
}

export default useAlert