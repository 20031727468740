import request from 'superagent'
import { UseMutationResult, useMutation } from 'react-query'
import { getApiServer } from '../../utils/domain'
import { CustomHttpError } from '../../types/CustomHttpError'

export default function useChangeForgotPassword(): UseMutationResult<any, CustomHttpError, any, any> {
    return useMutation(
        (values: any) => request.post(`${getApiServer()}/auth/change-forgot-password`).set('Accept', 'application/json').send(values).then((res) => res.body), {
        onError: (error: CustomHttpError) => {
            return error.response.text
        }
    })
}