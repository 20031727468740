import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { buildPatientId } from '../../../../../utils/fhirUtils/patient'
import { formatDateInTimeZone } from '../../../../../utils/helpers'
import { sortConditionsByDisplay } from '../../../../../utils/fhirUtils/sortConditions'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import PaginationButtons from '../../../../PaginationButtons'
interface Props {
    conditionData: any;
    patientData: any;
    isFetching: boolean;
    handleChangePage: (url: string) => void;
}


const ConditionList: FC<Props> = ({ conditionData, handleChangePage, patientData, isFetching }: Props) => {
    const patientConditions = conditionData && conditionData.entry ? conditionData.entry.map((condition: any) => condition.resource) : []

    const nextLink = conditionData && conditionData.link.find((l: any) => l.relation === 'next')
    const previousLink = conditionData && conditionData.link.find((l: any) => l.relation === 'previous')

    const getConditionRecordedDate = (item: any) => {
        return item.recordedDate ? formatDateInTimeZone(item.recordedDate, 'MM/dd/yyyy', 'UTC') : ''
    }




    // console.dir(patientConditions)
    return (
        <>
            {patientConditions.length > 0 && (
                <div className="mb-2">
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {`${conditionData?.total.toLocaleString()} record(s) found`}
                    </div>
                    <PaginationButtons
                        backDisabled={!previousLink}
                        nextDisabled={!nextLink}
                        onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                        onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                    />
                </div>
            )}

            <Table size="sm" hover>
                <thead>
                    <tr>
                        <th className="d-table-cell">Code</th>
                        <th className="d-none d-lg-table-cell">Category</th>
                        <th className="d-none d-lg-table-cell">Recorded Date</th>
                        <th className="d-none d-lg-table-cell">View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {isFetching ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className="d-flex">
                                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                                <span style={{ marginLeft: '10px' }}>Loading Member Diagnoses...</span>
                            </td>
                        </tr>
                    ) : patientConditions.length === 0 ? (
                        <tr>
                            <td colSpan={6} rowSpan={15} className="text-center">
                                No conditions found for this member.
                            </td>
                        </tr>
                    ) : (
                        patientConditions.map((condition: any) => (
                            <tr key={condition.id}>
                                <td className="d-table-cell">
                                    <Link to={`/patient/Condition/${condition.id}`}>
                                        {handleCodeableConcept(condition?.code)}
                                    </Link>
                                </td>
                                <td className="d-none d-lg-table-cell">
                                    {condition?.category && condition.category.length > 0 ? handleCodeableConcept(condition.category[0]) : 'N/A'}
                                </td>
                                <td className="d-none d-lg-table-cell">{getConditionRecordedDate(condition)}</td>
                                <td className="d-none d-lg-table-cell">
                                    <Link to={`/patient/Condition/${condition.id}`}>View Detail</Link>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
                <tfoot>
                    {patientConditions.length > 0 &&
                        <tr>
                            <td scope="row" colSpan={5}>
                                <PaginationButtons
                                    backDisabled={!previousLink}
                                    nextDisabled={!nextLink}
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                />
                            </td>
                        </tr>
                    }
                </tfoot>
            </Table>
        </>
    )
}

export default ConditionList