import { capitalizeFirstLetter, formatDateInTimeZone, handleCodeableConcept } from '../helpers'
import { ExplanationOfBenefitBundle } from '../../types/FHIRTypes/Bundle'
import { Period } from '../../types/FHIRTypes/Period'
import { Diagnosis, ExplanationOfBenefit, Payment } from '../../types/FHIRTypes/ExplanationOfBenefit'

export function buildEOBPayor(resource: ExplanationOfBenefit): string {
  const payor = resource?.contained?.[0]?.class?.[1]?.name ?? 'N/A'
  return payor
}

export function buildEOBClaimId(resource: any): string {

  return resource ? resource.identifier[0].value : 'N/A'
}

export function buildEOBStatus(resource: any): string {
  const eobStatus = resource ? resource.status : ''
  return capitalizeFirstLetter(eobStatus)
}

export function buildEOBUse(resource: any): string {
  return resource ? capitalizeFirstLetter(resource.use) : ''
}

export const buildEOBBillableStart = (period: Period): string => period?.start ? formatDateInTimeZone(period.start, 'MM/dd/yyyy', 'UTC') : ''

export const buildEOBBillableEnd = (period: Period): string => period?.end ? formatDateInTimeZone(period.end, 'MM/dd/yyyy', 'UTC') : ''

export const buildEOBCreatedDate = (createdDate: string): string => createdDate ? formatDateInTimeZone(createdDate, 'MM/dd/yyyy', 'UTC') : ''

export function buildEOBInsurer(resource: any): string {
  const payor = resource && resource?.type?.[0] ? resource?.type[0]?.coding[0]?.display : 'No Insurer Listed'
  return payor

}

export function buildEOBProvider(resource: any): string {
  return resource?.provider ? resource.provider.reference : ''
}

export function buildEOBRelated(resource: any): Array<string> {
  if (resource.related) {
    const eobRelated = resource.related
    const eobRelatedData = []
    for (let i = 0; i < eobRelated.length; i++) {
      const eobRelatedRelationship = resource ? eobRelated[i].relationship : ''
      const eobRelatedReference = resource ? eobRelated[i].reference : ''
      eobRelatedData.push(eobRelatedRelationship, eobRelatedReference)
    }
    return eobRelatedData
  }
  return []
}
export function buildEOBPayment(payment: Payment): string {
  const value = payment && payment.amount && payment.amount.value ? `$${payment.amount.value.toFixed(2)}` : '$0.00'
  return value
}

export function buildEOBPatientPayment(resource: any): string {
  // to add currency ${resource.payment.amount.currency}

  const memberLiability = resource.total.find((amount: any) =>
    amount.category.coding[0].code === 'memberliability'
  )
  const value = memberLiability ? `$${memberLiability.amount.value.toFixed(2)}` : '$0.00'
  return value
}

export function buildEOBPayee(resource: any): string {
  return `${resource?.payee.party.reference}`
}

export function buildEOBOutcome(outcome: string): string {
  return outcome
}

export function buildEobType(resource: any): string {

  return resource ? resource?.type?.coding[0]?.code.toLowerCase() : ''
}

export function buildEobTypeDisplay(resource: any): string {
  const code = resource?.type?.coding[0]?.code || ''
  return code.charAt(0).toUpperCase() + code.slice(1)
}

export const formatEobDataForTable = (eobs: ExplanationOfBenefitBundle): { eobId: string; payor: string; claimId: string; type: string; billableStart: string; billableEnd: string; }[] => {
  if (!eobs) return []
  const patientEobs = eobs.entry?.map((eob) => eob.resource) || []

  return patientEobs.map((patientEob) => ({
    eobId: patientEob.id,
    payor: patientEobs[0]?.contained?.[0]?.class?.[1]?.name ?? 'N/A',
    claimId: patientEob.identifier?.[0].value || '',
    type: handleCodeableConcept(patientEob.type),
    billableStart: patientEob.billablePeriod ? patientEob.billablePeriod.start || '' : '',
    billableEnd: patientEob.billablePeriod ? patientEob.billablePeriod.end || '' : ''
  }))
}

export const buildEOBDiagnosis = (diagnosis: Diagnosis[]): string => {
  if (!diagnosis) return ''
  const eobDiagnosis = diagnosis.map((diagnosis) => diagnosis.diagnosisCodeableConcept && handleCodeableConcept(diagnosis.diagnosisCodeableConcept))
  return eobDiagnosis.join(', ')
}
