import React, { FC } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { buildPeriod, capitalizeFirstLetter, formatDateWithFormat, handleCodeableConcept, handleExtension, handleIdentifier, handleReference } from '../../../../../utils/helpers'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import { CareTeamBundle } from '../../../../../types/FHIRTypes/Bundle'

interface Props {
  careTeamDetailId: string
  careTeamData: CareTeamBundle
  isFetching: boolean
}

const config: FieldConfig = {
  subject: { label: 'Patient', visibility: 'always' },
  status: { label: 'Care Team Status', visibility: 'always' },
  category: { label: 'Category', visibility: 'conditional' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  period: { label: 'Period', visibility: 'conditional' },
  participant: { label: 'Care Team Members', visibility: 'always' },
  telecom: { label: 'Care Team Contact', visibility: 'conditional' },
  text: { label: 'Care Team Summary', visibility: 'conditional' },
  name: { label: 'Name', visibility: 'never' },
  reasonCode: { label: 'Reason', visibility: 'never' },
  reasonReference: { label: 'Reason Reference', visibility: 'never' },
  managingOrganization: { label: 'Managing Organization', visibility: 'never' },
  note: { label: 'Note', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const CareTeamDetail: FC<Props> = ({ careTeamDetailId, careTeamData, isFetching }) => {

  const careTeamEntry = careTeamData?.entry?.find(
    (careTeam: any) => careTeam.resource.id === careTeamDetailId
  )

  const resource = careTeamEntry?.resource

  return (
    <>
      {isFetching && (
        <div className='d-flex dataContainer'>
          <Spinner
            as='span'
            animation='border'
            role='status'
            aria-hidden='true'
          />
          <span style={{ marginLeft: '10px' }}>Loading Care Team Data...</span>
        </div>
      )}
      <dl className='dataContainer'>
        {resource ? (
          <Card>
            <Card.Body>
              <Row>
                <ShowField field='subject' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.subject.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.subject)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='status' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{capitalizeFirstLetter(resource.status)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='category' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.category.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.category)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='encounter' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.encounter.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleReference(resource.encounter)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='period' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.period.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{buildPeriod(resource.period)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='participant' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.participant.label}</dt>
                  </Col>
                  <Col sm={9}>
                    {resource.participant?.map((participant: any, index: number) => {
                      const roles = participant.role?.map((role: any) => {
                        if (role.coding) {
                          return handleCodeableConcept(role)
                        } else if (role.extension) {
                          return handleExtension(role.extension)
                        }
                        return ''
                      }).join(', ')

                      const isExtension = participant.role?.some((role: any) => role.extension)
                      const isDataAbsentReason = isExtension && participant.role[0].extension[0].url === 'http://hl7.org/fhir/StructureDefinition/data-absent-reason'
                      const label = isDataAbsentReason ? 'Data absent reason' : roles
                      const value = isExtension ? roles : handleReference(participant.member)

                      return (
                        <div key={index}>
                          <dd>{label}: {value}</dd>
                        </div>
                      )
                    })}
                  </Col>
                </ShowField>
                <ShowField field='telecom' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.telecom.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {resource.telecom
                        ?.map((telecom: any) => `${telecom.system}: ${telecom.use}: ${telecom.value}`)
                        .join(', ')}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='text' config={config} resource={resource}>
                  <Col sm={12} className='my-2'>
                    <Card className='mx-2'>
                      <Card.Header className='d-flex justify-content-between'>
                        <dt>{config.text.label}</dt>
                        {resource.text?.status && (
                          <dd>
                            Status:{' '}
                            {capitalizeFirstLetter(
                              resource.text.status
                            )}
                          </dd>
                        )}
                      </Card.Header>
                      <Card.Body>
                        <dd
                          dangerouslySetInnerHTML={{
                            __html: resource.text?.div ?? '',
                          }}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </ShowField>
                <ShowField field='name' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.name.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.name}</dd>
                  </Col>
                </ShowField>
                <ShowField field='reasonCode' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.reasonCode.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleCodeableConcept(resource.reasonCode)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='reasonReference' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.reasonReference.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {handleReference(resource.reasonReference)}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='managingOrganization' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.managingOrganization.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {handleReference(resource.managingOrganization)}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='note' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.note.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.note?.map((note: any) => note.text).join(', ')}</dd>
                  </Col>
                </ShowField>
              </Row>
            </Card.Body>
            <Card.Footer className='footer'>
              <h6>FHIR Resource Metadata</h6>
              <Row>
                <ShowField field='resourceType' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.resourceType.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{capitalizeFirstLetter(resource.resourceType)}</dd>
                  </Col>
                </ShowField>
                <ShowField field='id' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.id.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.id}</dd>
                  </Col>
                </ShowField>
                <ShowField field='meta' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.meta.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {resource.meta?.lastUpdated
                        ? formatDateWithFormat(
                            resource.meta.lastUpdated,
                            'MM/dd/yyyy HH:mm:ss'
                          )
                        : ''}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='profile' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.profile.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.meta?.profile?.join(', ')}</dd>
                  </Col>
                </ShowField>
                <ShowField field='implicitRules' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.implicitRules.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.implicitRules}</dd>
                  </Col>
                </ShowField>
                <ShowField field='language' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.language.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.language}</dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{handleIdentifier(resource.identifier)}</dd>
                  </Col>
                </ShowField>
              </Row>
              <Row>
                <Col sm={12}>
                  <ProvenanceDetail resourceName='CareTeam' resourceId={careTeamDetailId} />
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        ) : (
          <div className='text-center'>
            {'No Care Teams found for this member.'}
          </div>
        )}
      </dl>
    </>
  )
}

export default CareTeamDetail