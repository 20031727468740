import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'

interface InfoPopoverProps {
    title: string;
    content: string;
}

const InfoPopover: React.FC<InfoPopoverProps> = ({ title, content }) => {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3" className='font-weight-bold'>{title}</Popover.Title>
            <Popover.Content>
                {content}
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={popover}
        >
            <BsInfoCircle className='ml-2' />
        </OverlayTrigger>
    )
}

export default InfoPopover