import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useFindMember(memberId: string, resource: string): any {
    const queryParams: any = {
        resource,
        memberId
    }
    return useQuery(['member'], async () => {
        return await request.get(`${getApiServer()}/fhir/resource`).query({ ...queryParams }).set('Accept', 'application/json').withCredentials().then((res) => res.body)
    }, {
        staleTime: Infinity,
        cacheTime: 5 * 60 * 1000,
        retry: 0,
        keepPreviousData: true,
        enabled: false
    })
}