import React, { FC } from 'react'
import { Reference as Target } from '../../../../types/FHIRTypes/Reference'

interface TargetProps {
    target: Target;
}

const TargetResource: FC<TargetProps> = ({ target }) => {
    return (
        <div>
            {target?.reference}
        </div>
    )
}

export default TargetResource
