import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useFhirResourceById(resourceId: string, resource: string, searchParams?: Record<string, any> ): any {

  return useQuery([searchParams, resourceId,  resource] , async () => {
    const resourceParams = {
        resource: resource,
        resourceId: resourceId || undefined,
        searchParams: searchParams || undefined
    }
    return await request.get(`${getApiServer()}/fhir/resource-by-id`).query(resourceParams).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 5 * 60 * 1000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}