import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import {
    companyMessage,
    companyNameFull,
    preferedName,
    dataPortalTitle,
    contactLink,
    contactEmail,
    contactPhone,
} from '../../assets/i18n/i18n'
import { FormattedMessage, IntlProvider } from 'react-intl'


const TermsAndConditions: FC = () => {
    return (
        <main className='content'>
        <IntlProvider locale='en' messages={companyMessage}>
            <Container>
                <h2 className='headerText'>Terms and Conditions</h2>

                <p>By accessing or using the {preferedName} {dataPortalTitle}, powered by ConnectID, TESCHGlobal and HealthLX, you agree to be bound by these Terms and Conditions.
                </p>

                <h3>1. Privacy and Security of Personal Information</h3>

                <p>1.1  By using this application, you acknowledge and agree that ConnectID, TESCHGlobal, HealthLX and {companyNameFull} (“we”) may collect, store, and use certain personal information, including but not limited to your name, address, and health plan information, including plan information and Explanation of Benefits (EOBs).</p>

                <p>1.2 We will take reasonable measures to protect the confidentiality and security of your personal information. </p>

                <p>1.3 You are responsible for maintaining the confidentiality of your login credentials and should not share them with any unauthorized person. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>

                <h3>2. Use of the {preferedName} {dataPortalTitle}</h3>

                <p>2.1 You may not use this application for any illegal or unauthorized purpose.</p>

                <p>2.2 You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of this application without our prior written consent.</p>

                <p>2.3 You agree not to impersonate any person or entity or falsely state or otherwise misrepresent yourself or your affiliation with any person or entity.</p>

                <h3>3. Intellectual Property Rights</h3>

                <p>3.1 All content and materials available on this application, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, are the property of our company or our content suppliers and are protected by applicable copyright laws.</p>

                <p>3.2 You may not modify, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any content or materials obtained from our application without our prior written consent.</p>

                <h3>4. Limitation of Liability</h3>

                <p>4.1 In no event shall ConnectID, TESCHGlobal, HealthLX or {companyNameFull}, its officers, directors, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of this application, including but not limited to the loss of data, loss of profits, or any other loss or damage of any kind.</p>

                <p>4.2 We do not warrant that this application will be error-free or uninterrupted, or that any defects will be corrected. We do not make any representations regarding the use or results of our application in terms of accuracy, reliability, or otherwise.</p>

                <h3>5. Changes to the Terms and Conditions</h3>

                <p>5.1 We reserve the right to modify or update these Terms and Conditions at any time without prior notice. By continuing to use this application after any changes are made, you agree to be bound by the revised Terms and Conditions.</p>

                <p>5.2 It is your responsibility to review these Terms and Conditions periodically for any changes.</p>

                <h3>6. Governing Law and Jurisdiction</h3>

                <p>6.1 These Terms and Conditions shall be governed by and construed in accordance with the laws of Milwaukee, WI. Any dispute arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Milwaukee, WI.</p>

                <h3>7. Contact Us</h3>

                <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at flaresupport@teschglobal.com</p>

                <p>By using this online application, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>


            </Container>
        </IntlProvider>
        </main>
    )
}

export default TermsAndConditions