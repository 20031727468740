import React, { FC } from 'react'
import { useState } from 'react'
import { Form, Alert, Row, Col, Button, Spinner } from 'react-bootstrap'
import PasswordInput from '../../../components/PasswordInput'
import { Link } from 'react-router-dom'
interface Props {
  formHandler: any;
  alertState: { show: boolean; variant: string; message: string; };
  closeAlert: () => void;
  openForgotModal: (type: string) => void;
  isLoading: boolean;
}

const LoginForm: FC<Props> = ({ formHandler, alertState, closeAlert, openForgotModal, isLoading }: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <h3 className='headerText'>Log In</h3>
      <Form noValidate onSubmit={formHandler.handleSubmit} id='login'>
        {
          alertState.show && (
            <Alert variant={alertState.variant} dismissible onClose={closeAlert}>
              {alertState.message}
            </Alert>
          )
        }
        <Form.Group className='custom'>
          <Form.Label>
            Username:
          </Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Username'
            name='username'
            value={formHandler.values.username}
            onChange={formHandler.handleChange}
            onBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.username && formHandler.touched.username}
            autoComplete='username'
            id='username'
          />
          <Row>
            <Col>
              {
                !!formHandler.errors.username && formHandler.touched.username && (
                  <Form.Text className='text-danger'>
                    {formHandler.errors.username}
                  </Form.Text>
                )
              }
            </Col>
            <Col className='text-right'>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault()
                  openForgotModal('Username')
                }}
                className='small'
              >
                Forgot Username?
              </Link>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className='custom'>
          <Form.Label>
            Password:
          </Form.Label>
          <PasswordInput
            showPassword={showPassword}
            placeholder='Enter Password'
            name='password'
            value={formHandler.values.password}
            handleChange={formHandler.handleChange}
            handleBlur={formHandler.handleBlur}
            isInvalid={!!formHandler.errors.password && formHandler.touched.password}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            autoComplete='current-password'
          />
          <Row>
            <Col>
              {
                !!formHandler.errors.password && formHandler.touched.password && (
                  <Form.Text className='text-danger'>
                    {formHandler.errors.password}
                  </Form.Text>
                )
              }
            </Col>
            <Col className='text-right'>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault()
                  openForgotModal('Password')
                }}
                className='small'
              >
                Forgot Password?
              </Link>
            </Col>
          </Row>
        </Form.Group>
        <div>
          {!isLoading ?
            <Button type='submit' className='custom'>Log In</Button>
            :
            <Button type='submit' className='custom' disabled={isLoading}>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                size='sm'
              />
            </Button>
          }
        </div>
      </Form>
    </>
  )
}

export default LoginForm