import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useAccount(accountId: string): any {
  return useQuery(['account', accountId], async () => {
    return await request.get(`${getApiServer()}/accounts/${accountId}`).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    enabled: false
  })
}