import React from 'react'
import { Observation } from '../../../../../types/FHIRTypes/Observation'
import { Col, Row } from 'react-bootstrap'
import { appConfig } from '../../../../../assets/customizations/config'
import { formatDateInTimeZone } from '../../../../../utils/helpers'

interface SmokingProps {
    resource: Observation;
    patientData: any
}

const Smoking: React.FC<SmokingProps> = ({ resource, patientData }) => {
    return (
        <>
            <dl className='dataContainer'>
                <Row>
                    <Col sm={3}>
                        <dt>Member ID</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{patientData?.id.replace(appConfig.client_prefix, '')}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Effective Date</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{formatDateInTimeZone(resource?.effectiveDateTime ?? '', 'MM/dd/yyyy', 'UTC')}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Status</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{resource?.valueCodeableConcept && resource?.valueCodeableConcept.text ? resource?.valueCodeableConcept.text : 'No Smoking Status Listed'}</dd>
                    </Col>
                    <Col sm={3}>
                        <dt>Code</dt>
                    </Col>
                    <Col sm={9}>
                        <dd>{resource?.code?.coding?.[0]?.code} ({resource?.code?.coding?.[0]?.display})</dd>
                    </Col>
                </Row>
            </dl>
        </>
    )
}

export default Smoking