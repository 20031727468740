export enum AdjudicationCodeEnum {
    submitted = 'Submitted',
    copay = 'CoPay',
    eligible = 'Eligible',
    deductible = 'Deductible',
    benefit = 'Benefit',
    coinsurance = 'Coinsurance',
    noncovered = 'Noncovered',
    priorpayerpaid = 'Prior payer paid',
    paidbypatient = 'Paid by patient',
    paidbypatientcash = 'Paid by patient - cash',
    paidbypatientother = 'Paid by patient - other',
    paidtopatient = 'Paid to patient',
    paidtoprovider = 'Paid to provider',
    memberliability = 'Member Liability',
    discount = 'Discount',
    drugcost = 'Drug cost',
    denialreason = 'Denial Reason',
}