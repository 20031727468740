import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useAccounts(): any {
  return useQuery(['accounts'], async () => {
    return await request.get(`${getApiServer()}/accounts`).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 0,
    keepPreviousData: true,
    enabled: true
  })
}