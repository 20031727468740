import { useMutation, useQueryClient } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useSaveAccount(accountId: string): any {
  const queryClient = useQueryClient()
  return useMutation(
    (values: any) => request.put(`${getApiServer()}/accounts/lock/toggle/${accountId}`).set('Accept', 'application/json').withCredentials().send(values).then((res) => res.body),
    {
      onMutate: (values) => {
        const oldAccount: any = queryClient.getQueryData(['account', accountId])
        const updatedAccount = {
          ...oldAccount,
          ...values
        }

        queryClient.setQueryData(['app', accountId], updatedAccount)

        const accounts: any = queryClient.getQueryData('accounts')
        if (accounts) {
          const accountIndex = accounts.accounts.findIndex((account: any) => account.id === parseInt(accountId, 10))

          queryClient.setQueryData('accounts', {
            accounts: [
              ...accounts.accounts.splice(0, accountIndex),
              updatedAccount,
              ...accounts.accounts.splice(accountIndex + 1)
            ]
          })
        }
      },
      onSuccess: async (values) => {
        await queryClient.refetchQueries(['account', accountId])
        await queryClient.refetchQueries('accounts')
      }
    }
  )
}