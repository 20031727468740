import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useFhirMetadata(): any {
  return useQuery('metadata', async () => {
    return await request.get(`${getApiServer()}/fhir/metadata`).set('Accept', 'application/json').then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 3600000,
    retry: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })
}