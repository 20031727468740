import React, { FC } from 'react'
import { Identifier as ResourceIdentifier } from '../../../../types/FHIRTypes/Identifier'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface IdentifierProps {
    identifiers: ResourceIdentifier[] | undefined;
}

const Identifier: FC<IdentifierProps> = ({ identifiers }) => {

    const getIdentifierDisplay = (identifier: ResourceIdentifier): string => {
        const typeDisplay = identifier.type?.coding?.[0]?.display ? `${identifier.type.coding[0].display}: ` : ''
        const value = identifier.value || ''
        return `${typeDisplay}${value}`
    }

    return (
        <>
            {
                identifiers?.map((identifier, index) => (
                    <div key={`identifier-${index}`}>
                        {getContentOrFallback(getIdentifierDisplay(identifier))}
                    </div>
                ))
            }
        </>
    )
}

export default Identifier