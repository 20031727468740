/* eslint-disable react/display-name */
import React, { FC } from 'react'
import { Breadcrumb, Container, Table, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ThirdPartyApp from '../../../types/ThirdPartyApp'
import { capitalizeFirstLetter } from '../../../utils/helpers'

// import './index.css'
import '../../../App.scss'

interface Props {
  apps: Array<ThirdPartyApp>;
  status: string;
  changeStatus: (event: any) => void;
}

const formatLink = (cellContent: string) => <a href={cellContent} target='_blank' rel="noreferrer" >{cellContent}</a>

const Applications: FC<Props> = ({ apps, status, changeStatus }: Props) => {

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {/* <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Apps</Breadcrumb.Item>
      </Breadcrumb> */}
      <h2>Apps</h2>
      <Table size='sm' hover>
        <thead>
          <tr>
            <th scope='col' colSpan={4}>&nbsp;</th>
            <th scope="col">
              <Form.Control
                name='appStatus'
                as='select'
                value={status}
                onChange={(evt: any) => changeStatus(evt)}
              >
                <option value='All'>All</option>
                <option value='NEW'>New</option>
                <option value='APPROVED'>Approved</option>
                <option value='NOT APPROVED'>Not Approved</option>
                <option value='INACTIVE'>Inactive</option>
              </Form.Control>
            </th>
            <th scope="col" colSpan={2}>&nbsp;</th>
          </tr>
          <tr>
            <th scope="col">App Name</th>
            <th scope="col">Company Name</th>
            <th scope="col">Web URL</th>
            <th scope="col">Carin Attested?</th>
            <th scope="col">App Status</th>
            <th scope="col">View Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            apps && apps.length > 0 ? (
              <>
                {
                  apps.map((app) => (
                    <tr key={app.appId}>
                      <td>{app.appName}</td>
                      <td>{app.companyName}</td>
                      <td>{formatLink(app.webUrl)}</td>
                      <td>{app.readCarin ? 'Yes' : 'No'}</td>
                      <td>{capitalizeFirstLetter(app.status)}</td>
                      <td>
                        <Link to={`/app/${app.appId}`}>View Detail</Link>
                      </td>
                    </tr>
                  ))
                }
              </>
            ) : null
          }
        </tbody>
      </Table>
    </Container>
  )
}

export default Applications