import { useQuery } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

export default function useFhirResource(memberId: string, resource: string, count = 1, getPages = '', getPagesOffset = '', pageId = ''): any {
  const queryParams: any = {
    count: count || undefined,
    pageId: pageId || undefined,
    resource: resource || undefined,
    memberId: memberId || undefined,
    getPages: getPages || undefined,
    getPagesOffset: getPagesOffset || undefined,
  }

  return useQuery([memberId, resource, count, getPages, getPagesOffset, pageId], async () => {

    return await request.get(`${getApiServer()}/fhir/resource`).query({ ...queryParams }).set('Accept', 'application/json').withCredentials().then((res) => res.body)
  }, {
    staleTime: Infinity,
    cacheTime: 5 * 60 * 1000,
    retry: 0,
    keepPreviousData: true,
    enabled: false
  })
}